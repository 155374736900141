<template>
  <svg
    class="NtpIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99963 12.0006L10.9996 14.0006L14.9996 10.0006M7.83437 4.69766C8.55191 4.6404 9.23309 4.35824 9.78095 3.89136C11.0594 2.80184 12.9398 2.80184 14.2183 3.89136C14.7662 4.35824 15.4474 4.6404 16.1649 4.69766C17.8393 4.83128 19.169 6.16092 19.3026 7.83535C19.3598 8.55288 19.642 9.23407 20.1089 9.78193C21.1984 11.0604 21.1984 12.9408 20.1089 14.2193C19.642 14.7672 19.3598 15.4483 19.3026 16.1659C19.169 17.8403 17.8393 19.1699 16.1649 19.3036C15.4474 19.3608 14.7662 19.643 14.2183 20.1099C12.9398 21.1994 11.0594 21.1994 9.78095 20.1099C9.23309 19.643 8.55191 19.3608 7.83437 19.3036C6.15994 19.1699 4.8303 17.8403 4.69668 16.1659C4.63942 15.4483 4.35727 14.7672 3.89038 14.2193C2.80086 12.9408 2.80086 11.0604 3.89038 9.78193C4.35727 9.23407 4.63942 8.55288 4.69668 7.83535C4.8303 6.16092 6.15994 4.83128 7.83437 4.69766Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpBadgeCheckIcon",
});
</script>
