<template>
  <svg
    class="NtpIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2929 13.2933C16.2886 13.7474 15.1738 14.0003 14 14.0003C9.58172 14.0003 6 10.4185 6 6.00026C6 4.82629 6.25287 3.71138 6.70712 2.70703C3.93137 3.96228 2 6.75563 2 10.0001C2 14.4183 5.58172 18.0001 10 18.0001C13.2443 18.0001 16.0376 16.0689 17.2929 13.2933Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpMoonIcon",
});
</script>
