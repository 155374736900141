/// <reference types="vite/types/importMeta" />
import { Axios } from 'axios-observable';
import { map } from 'rxjs';
import { PingResponse, PortalLoginResponse, RefreshTokenResponse } from '../../responses/intranet/auth.response';
import { HealthCheckResponse, SuccessSingleResponse } from '../../responses/base.response';
import { PortalLoginRequest } from '../../requests/intranet//auth.request';
import { apiPathFn } from '@ntprsrf-frontend/common/src/utils/api';
import { HEALTHCHECK_STATUS } from '../../constants/actuator.constants';
import { MaintenanceScheduleModel } from '../../models/intranet/auth.models';

const apiPath = apiPathFn(import.meta.env.VITE_INTRANET_AUTH_SERVICE);

export function healthcheck() {
  return Axios.get<HealthCheckResponse>(apiPath(['actuator', 'health']))
    .pipe(
      map((response) => response.data.status === HEALTHCHECK_STATUS.UP)
    )
}

/* internet usage only */
export function loginMop(loginRequest: PortalLoginRequest) {
  return Axios.post<PortalLoginResponse>(apiPath(['login', 'MOP']), loginRequest)
    .pipe(
      map((response) => response.data)
    );
}

/* intranet usage only */
export function loginWog(loginRequest: PortalLoginRequest) {
  return Axios.post<PortalLoginResponse>(apiPath(['login', 'WOG']), loginRequest)
    .pipe(
      map((response) => response.data)
    );
}

export function logout() {
  return Axios.post<SuccessSingleResponse<null>>(apiPath(['logout']), undefined, {
    headers: {
      "Content-Type": "application/json"
    }
  })
    .pipe(
      map((response) => response.data)
    );
}

export function verifyToken() {
  return Axios.post<RefreshTokenResponse>(apiPath(['verify', 'token']), null)
    .pipe(
      map((response) => response.data)
    );
}

export function refreshToken() {
  return Axios.post<SuccessSingleResponse<null>>(apiPath(['refresh', 'portal-token']))
    .pipe(
      map((response) => response.data)
    );
}

export function ping() {
  return Axios.post<PingResponse>(apiPath(['ping']))
    .pipe(
      map((response) => response.data)
    );
}

export function ssoLogin(token: string) {
  return Axios.post<PortalLoginResponse>(apiPath(['sso', 'login']), {
    token
  })
    .pipe(
      map((response) => response.data)
    );
}

export function corppassLogin() {
  return Axios.get<SuccessSingleResponse<string>>(apiPath(['corppass', 'login']))
    .pipe(
      map((response) => response.data.data)
    );
}

export function corppassAuthorization(code: string, state: string) {
  return Axios.get<PortalLoginResponse>(apiPath(['corppass', 'authorization']), {
    params: {
      code,
      state
    }
  })
    .pipe(
      map((response) => response.data)
    );
}

export function adfsGenerateRequest() {
  return Axios.post<SuccessSingleResponse<string>>(apiPath(['adfs', 'newsamlrequest']))
    .pipe(
      map((response) => response.data)
    );
}

export function postRequestToURL(url: string, SAMLRequest: string) {
  const form = document.createElement('form');
  form.method = "post";
  form.action = url;
  const params = { SAMLRequest }
  const hiddenField = document.createElement('input');
  hiddenField.type = 'hidden';
  hiddenField.name = "SAMLRequest";
  hiddenField.value = params.SAMLRequest;
  form.appendChild(hiddenField);

  document.body.appendChild(form);
  form.submit();
}

export function getMaintenanceSchedule() {
  return Axios.get<MaintenanceScheduleModel>(apiPath(['maintenance', 'info']))
    .pipe(
      map((response) => response.data)
    );
}