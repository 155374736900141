import Axios from "axios-observable";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { ErrorResponse } from "@ntprsrf-frontend/service/src/responses/base.response";
import useNotification from "../components/NtpToast";
import { useVersionStore } from "../stores/version.store";

const errorNotification = useNotification("danger");

let abortController = new AbortController(); 

const errorHandler = (message: string, requestUrl: string = '', status?: number = 403) => {
	const toastMessagesToBeSkipped = ['Token is not in a right format', 'Share Request Not Found', 'Service is Not Active']
	const requestUrlToBeSkipped = ['hces/upload', 'hces/list/internal', 'track-and-trace/containerNumber', 'permit-return/query', 'government-portal'];

	let skipRequestUrl = false;

	requestUrlToBeSkipped.forEach(v => {
		if(!skipRequestUrl) {
			skipRequestUrl = requestUrl.includes(v);
		}
	})

	if (!toastMessagesToBeSkipped.includes(message) && (!skipRequestUrl || (skipRequestUrl && status == 401 && requestUrl.includes('government-portal')))) { 
		errorNotification("Error", message);
	}
};

const getCookie = (cookieName: string) => {
	let cookie: {[key: string]: string} = {};
	document.cookie.split(';').forEach(function(el) {
	  let [key,value] = el.split('=');
	  cookie[key.trim()] = value;
	})
	return cookie[cookieName];
};

export const DOWNLOAD_CONFIG: AxiosRequestConfig = {
	responseType: "blob",
	validateStatus: (s: number) => [200, 400].includes(s),
}

export function credentialInterceptor() {
	Axios.defaults.withCredentials = true;
}

export const requestFulfilledHandler = (config: AxiosRequestConfig) => {
	config.url = config.url
		?.replace(/\/+/g, "/")
		.replace(/^https:\//, "https://");
	config.headers = {
		...config.headers,
		'Cache-control': 'no-store',
		'Pragma': 'no-cache'
	}
	const ntpVer = import.meta.env.VITE_VERSION_CODE;
	if (ntpVer) {
		config.headers['X-Ntp-Ver'] = ntpVer;
	}
	const csrfToken = getCookie('ntp2-csrf');
	if (csrfToken) {
		config.headers['X-CSRF-TOKEN'] = csrfToken;
	}
	if (abortController.signal.aborted) {
		abortController = new AbortController();
	}
	config.signal = abortController.signal;
	return config;
}

export const requestRejectedHandler = (error: AxiosError) => Promise.reject(error);

export const responseFulfilledHandler = async (res: AxiosResponse) => {
	const versionStore = useVersionStore();
	const ntpVersion = res.headers["x-ntp-ver"];
	if (res.config.url?.endsWith('logout')) {
		abortController.abort();
		abortController = new AbortController();
	}
	if(ntpVersion && versionStore.version !== ntpVersion) {
		versionStore.setVersion(ntpVersion);
	}
	if (res.status === 400 && res.config.responseType === "blob") {
		const errRes: ErrorResponse = JSON.parse(await res.data.text());
		errorHandler(errRes.message, res.config.url);
		throw new AxiosError(errRes.message);
	}
	return res;
}

export const responseRejectedHandler = (err: Error | AxiosError<ErrorResponse>) => {
	if (axios.isCancel(err)) {
		return {
			data: {
				message: '',
				pageNumber: 0,
				pageSize: 0,
				total: 0,
				totalPage: 0,
				data: []
			}
		}
	} else if (axios.isAxiosError(err)) {
		const status = (err as AxiosError<ErrorResponse>).request?.status;
		const url = (err as AxiosError<ErrorResponse>).request?.responseURL;
		const message =
			(err as AxiosError<ErrorResponse>).response?.data.message ??
			(err as AxiosError<ErrorResponse>).message;
		const isLogout401 = status === 401 && url.includes("logout");
		if (!isLogout401) errorHandler(message, url);
	}
	throw err;
}

export function normalizeRequestInterceptor() {
	return Axios.interceptors.request.use(
		requestFulfilledHandler,
		requestRejectedHandler,
	);
}

export function handleErrorInterceptor() {
	return Axios.interceptors.response.use(
		responseFulfilledHandler,
		responseRejectedHandler,
	);
}
