import { shareFilesBy } from "@ntprsrf-frontend/service/src/constants/share-files-type.constant";
import { getCompanyDetailsByUEN } from "@ntprsrf-frontend/service/src/services/intranet/company.service";
import { getDataConsentTypeBySlug } from "@ntprsrf-frontend/service/src/services/intranet/dataConsent.service";
import { getFileShareServiceDetail } from "@ntprsrf-frontend/service/src/services/intranet/fileShareRequest.service";
import { getVASBySlug } from "@ntprsrf-frontend/service/src/services/intranet/vas.service";
import { startCase } from "lodash";
import { defineStore } from "pinia";
import { of, map, firstValueFrom, Observable } from "rxjs";
import { RouteLocationNormalized } from "vue-router";
import useAuthStore from "./auth.store";
import useCompanyStore from "./company.store";

export type BreadcrumbInfo = {
  title: string;
  subtitle: string;
  navTitle: string;
};

export const strBreadcrumbInfo = (obs: Observable<string>) => obs.pipe(
  map(res => <BreadcrumbInfo>({
    title: res,
    subtitle: '',
    navTitle: res
  }))
)

export interface BreadcrumbType {
  routePath: string;
  routeParamName: string;
  routeParamValue: string;
  breadcrumb: BreadcrumbInfo;
}

function loadParamValue(
  paramName: string,
  paramValue: string,
  route: RouteLocationNormalized
): Observable<BreadcrumbInfo> {
  switch (paramName) {
    case "toCompanyUEN":
    case "companyUEN":
    case "otherCompanyUEN":
      const companyStore = useCompanyStore();
      const companyDetail = companyStore.detail;

      const companyDetailObv = companyDetail && companyDetail.companyUEN === paramValue ? of({
        message: "",
        data: companyDetail
      }) : getCompanyDetailsByUEN(paramValue);

      if (route.name === 'E_GUARANTEE_ADD_FILES' && route.meta.label) {
        return companyDetailObv.pipe(
          map((response) => ({
            title: route.meta.label as string,
            subtitle: `for ${response.data.companyName}`,
            navTitle: route.meta.label as string
          })),
        );
      }

      return companyDetailObv.pipe(
        map((response) => response.data ? response.data.companyName : ""),
        strBreadcrumbInfo
      );
    case "vasId":
      return getFileShareServiceDetail({
        valueAddedServiceId: paramValue,
        companyUEN: route.params.otherCompanyUEN as string
      }).pipe(
        map(
          (response) => {
            const company = route.params.otherCompanyUEN === response.toCompany.companyUEN ? response.toCompany : response.fromCompany;
            return ({
              title: company.companyName,
              subtitle: `for ${response.valueAddedService?.serviceName}`,
              navTitle: `${company.companyName} - ${response.valueAddedService?.serviceName}`,
            })
          }
        )
      );
    case "slug":
    case "vasSlug":
      return getVASBySlug(paramValue).pipe(
        map(
          (response) => ({
            title: response.company.companyName,
            subtitle: `for ${response.serviceName}`,
            navTitle: `${response.company.companyName} - ${response.serviceName}`,
          })
        )
      );
    case "dataConsentTypeSlug":
      return getDataConsentTypeBySlug(paramValue).pipe(
        map((response) => response.data ? response.data.name : ""),
        strBreadcrumbInfo
      );
    case "eSharePath":
      return of(paramValue === shareFilesBy ? "Received" : "Sent To").pipe(
        strBreadcrumbInfo
      );
    case "serviceName": 
      return of(paramValue.split("-").join(" ")).pipe(
        strBreadcrumbInfo
      );
    case "sharePath":
    default:
      return of(startCase(paramValue.split("-").join(" "))).pipe(
        strBreadcrumbInfo
      );
  }
}

const useBreadcrumbStore = defineStore("breadcrumb", {
  state: () =>
    <
    {
      routeParams: Record<string, BreadcrumbType>;
      loading: boolean;
    }
    >{
      routeParams: {},
      loading: false,
    },
  getters: {
    getBreadcrumbLabel: (state) => (paramName: string) => {
      const breadcrumb = state.routeParams[paramName];
      return breadcrumb ? breadcrumb.breadcrumb : null;
    },
  },
  actions: {
    reset() {
      this.routeParams = {};
    },
    async updateBreadcrumb(route: RouteLocationNormalized) {
      const _store = this;
      const params = route.params as Record<string, string>;
      const routePath = route.path;
      const isLogin = useAuthStore().isLogin;

      const updateStore = async (paramName: string) => {
        if (isLogin && params[paramName]) {
          const value = await firstValueFrom(
            loadParamValue(paramName, params[paramName], route)
          );
          _store.routeParams[paramName] = {
            routePath,
            routeParamName: paramName,
            routeParamValue: params[paramName],
            breadcrumb: value,
          };
        }
      }

      let promises: Promise<void>[] = [];
      Object.keys(params)
        .filter(
          (paramName) =>
            !(
              (
                _store.routeParams[paramName] && // param has been recorded
                routePath.startsWith(_store.routeParams[paramName].routePath)
              ) // recorded param belongs to parent
            )
        ).forEach(x => {
          promises.push(updateStore(x));
        });
        
      Promise.all(promises);
    },
  },
});

export default useBreadcrumbStore;
