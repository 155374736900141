<template>
  <svg
    class="NtpIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.38268 3.07688C9.75636 3.23166 10 3.5963 10 4.00076V16.0008C10 16.4052 9.75636 16.7699 9.38268 16.9246C9.00901 17.0794 8.57889 16.9939 8.29289 16.7079L4.58579 13.0008H2C1.44772 13.0008 1 12.553 1 12.0008V8.00076C1 7.44848 1.44772 7.00076 2 7.00076H4.58579L8.29289 3.29365C8.57889 3.00766 9.00901 2.9221 9.38268 3.07688Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.6568 2.92961C15.0474 2.53909 15.6805 2.53909 16.0711 2.92961C17.8796 4.73816 19 7.23953 19 10.0007C19 12.7618 17.8796 15.2632 16.0711 17.0717C15.6805 17.4623 15.0474 17.4623 14.6568 17.0717C14.2663 16.6812 14.2663 16.0481 14.6568 15.6575C16.1057 14.2087 17 12.2101 17 10.0007C17 7.79126 16.1057 5.79264 14.6568 4.34383C14.2663 3.9533 14.2663 3.32014 14.6568 2.92961ZM11.8284 5.75804C12.2189 5.36752 12.8521 5.36752 13.2426 5.75804C13.7685 6.28392 14.1976 6.9076 14.5003 7.60031C14.822 8.33665 15 9.1492 15 10.0007C15 11.6573 14.3273 13.1586 13.2426 14.2433C12.8521 14.6338 12.2189 14.6338 11.8284 14.2433C11.4379 13.8528 11.4379 13.2196 11.8284 12.8291C12.5534 12.1042 13 11.1055 13 10.0007C13 9.42995 12.8811 8.88963 12.6676 8.40105C12.4663 7.94031 12.1802 7.524 11.8284 7.17225C11.4379 6.78173 11.4379 6.14856 11.8284 5.75804Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpVolumeUpIcon",
});
</script>
