<template>
  <svg
    class="NtpIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 4C2.89543 4 2 4.89543 2 6V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V8C18 6.89543 17.1046 6 16 6H11L9 4H4ZM11 9C11 8.44772 10.5523 8 10 8C9.44772 8 9 8.44772 9 9V10.5858L8.70711 10.2929C8.31658 9.90237 7.68342 9.90237 7.29289 10.2929C6.90237 10.6834 6.90237 11.3166 7.29289 11.7071L9.29289 13.7071C9.68342 14.0976 10.3166 14.0976 10.7071 13.7071L12.7071 11.7071C13.0976 11.3166 13.0976 10.6834 12.7071 10.2929C12.3166 9.90237 11.6834 9.90237 11.2929 10.2929L11 10.5858V9Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpFolderDownloadIcon",
});
</script>
