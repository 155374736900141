<template>
  <svg
    class="NtpIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 3L12.4472 2.10557C12.1657 1.96481 11.8343 1.96481 11.5528 2.10557L12 3ZM20 7H21C21 6.62123 20.786 6.27496 20.4472 6.10557L20 7ZM4 7L3.55279 6.10557C3.214 6.27496 3 6.62123 3 7H4ZM20 17L20.4472 17.8944C20.786 17.725 21 17.3788 21 17H20ZM12 21L11.5528 21.8944C11.8343 22.0352 12.1657 22.0352 12.4472 21.8944L12 21ZM4 17H3C3 17.3788 3.214 17.725 3.55279 17.8944L4 17ZM11.5528 3.89443L19.5528 7.89443L20.4472 6.10557L12.4472 2.10557L11.5528 3.89443ZM19.5528 6.10557L11.5528 10.1056L12.4472 11.8944L20.4472 7.89443L19.5528 6.10557ZM12.4472 10.1056L4.44721 6.10557L3.55279 7.89443L11.5528 11.8944L12.4472 10.1056ZM4.44721 7.89443L12.4472 3.89443L11.5528 2.10557L3.55279 6.10557L4.44721 7.89443ZM19.5528 16.1056L11.5528 20.1056L12.4472 21.8944L20.4472 17.8944L19.5528 16.1056ZM12.4472 20.1056L4.44721 16.1056L3.55279 17.8944L11.5528 21.8944L12.4472 20.1056ZM5 17V7H3V17H5ZM21 17V7H19V17H21ZM11 11V21H13V11H11Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpCubeIcon",
});
</script>
