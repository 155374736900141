<template>
  <svg
    class="NtpIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.94 6.41338C2.35524 6.77886 2 7.4198 2 8.10938V16.0009C2 17.1054 2.89543 18.0009 4 18.0009H16C17.1046 18.0009 18 17.1054 18 16.0009V8.10938C18 7.4198 17.6448 6.77886 17.06 6.41338L11.06 2.66338C10.4115 2.25804 9.58854 2.25804 8.94 2.66338L2.94 6.41338ZM5.5547 8.83559C5.09517 8.52924 4.4743 8.65341 4.16795 9.11294C3.8616 9.57247 3.98577 10.1933 4.4453 10.4997L9.4453 13.833C9.7812 14.057 10.2188 14.057 10.5547 13.833L15.5547 10.4997C16.0142 10.1933 16.1384 9.57247 15.8321 9.11294C15.5257 8.65341 14.9048 8.52924 14.4453 8.83559L10 11.7991L5.5547 8.83559Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpMailOpenIcon",
});
</script>
