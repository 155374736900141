<template>
  <svg
    class="NtpIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 16H14H13ZM13 8H12V8L13 8ZM17.2929 7.29289L18 6.58579L18 6.58579L17.2929 7.29289ZM20.7071 10.7071L21.4142 10V10L20.7071 10.7071ZM4 6H12V4H4V6ZM12 6V16H14V6H12ZM4 16V6H2V16H4ZM5 16H4V18H5V16ZM12 16H9V18H12V16ZM2 16C2 17.1046 2.89543 18 4 18V16H2ZM12 16V18C13.1046 18 14 17.1046 14 16H12ZM12 6H12H14C14 4.89543 13.1046 4 12 4V6ZM4 4C2.89543 4 2 4.89543 2 6H4V6V4ZM14 16L14 8L12 8L12 16H14ZM14 8H16.5858V6H14V8ZM20 11.4142V16H22V11.4142H20ZM16.5858 8L20 11.4142L21.4142 10L18 6.58579L16.5858 8ZM15 16H14V18H15V16ZM20 16H19V18H20V16ZM22 11.4142C22 10.8838 21.7893 10.3751 21.4142 10L20 11.4142L20 11.4142H22ZM16.5858 8L16.5858 8L18 6.58579C17.6249 6.21071 17.1162 6 16.5858 6V8ZM12 16C12 17.1046 12.8954 18 14 18V16H14H12ZM20 16V18C21.1046 18 22 17.1046 22 16H20ZM14 8V8V6C12.8954 6 12 6.89543 12 8H14ZM8 17C8 17.5523 7.55228 18 7 18V20C8.65685 20 10 18.6569 10 17H8ZM7 18C6.44772 18 6 17.5523 6 17H4C4 18.6569 5.34315 20 7 20V18ZM6 17C6 16.4477 6.44772 16 7 16V14C5.34315 14 4 15.3431 4 17H6ZM7 16C7.55228 16 8 16.4477 8 17H10C10 15.3431 8.65685 14 7 14V16ZM18 17C18 17.5523 17.5523 18 17 18V20C18.6569 20 20 18.6569 20 17H18ZM17 18C16.4477 18 16 17.5523 16 17H14C14 18.6569 15.3431 20 17 20V18ZM16 17C16 16.4477 16.4477 16 17 16V14C15.3431 14 14 15.3431 14 17H16ZM17 16C17.5523 16 18 16.4477 18 17H20C20 15.3431 18.6569 14 17 14V16Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpTruckIcon",
});
</script>
