<template>
  <svg
    class="NtpIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.67163 1.91135C6.52869 1.37788 5.98035 1.0613 5.44688 1.20424C4.91342 1.34719 4.59684 1.89552 4.73978 2.42899L4.9986 3.39491C5.14154 3.92838 5.68988 4.24496 6.22334 4.10202C6.75681 3.95908 7.07339 3.41074 6.93045 2.87728L6.67163 1.91135Z"
      fill="currentColor"
    />
    <path
      d="M2.42899 4.73978C1.89552 4.59684 1.34719 4.91342 1.20424 5.44688C1.0613 5.98035 1.37788 6.52869 1.91135 6.67163L2.87728 6.93045C3.41074 7.07339 3.95908 6.75681 4.10202 6.22334C4.24496 5.68988 3.92838 5.14154 3.39491 4.9986L2.42899 4.73978Z"
      fill="currentColor"
    />
    <path
      d="M11.2424 4.17137C11.633 3.78085 11.633 3.14768 11.2424 2.75716C10.8519 2.36663 10.2187 2.36663 9.82823 2.75716L9.12112 3.46427C8.73059 3.85479 8.73059 4.48795 9.12112 4.87848C9.51164 5.269 10.1448 5.269 10.5353 4.87848L11.2424 4.17137Z"
      fill="currentColor"
    />
    <path
      d="M4.17137 11.2424L4.87848 10.5353C5.269 10.1448 5.269 9.51164 4.87848 9.12112C4.48795 8.7306 3.85479 8.7306 3.46427 9.12112L2.75716 9.82823C2.36663 10.2188 2.36663 10.8519 2.75716 11.2424C3.14768 11.633 3.78085 11.633 4.17137 11.2424Z"
      fill="currentColor"
    />
    <path
      d="M7.3713 6.07139C6.99988 5.92283 6.57566 6.0099 6.2928 6.29276C6.00994 6.57563 5.92286 6.99984 6.07143 7.37126L10.0714 17.3713C10.2177 17.7369 10.5649 17.9826 10.9583 17.999C11.3518 18.0154 11.7182 17.7993 11.8943 17.4471L13.2739 14.688L16.2928 17.707C16.6833 18.0975 17.3165 18.0975 17.707 17.707C18.0975 17.3165 18.0975 16.6833 17.707 16.2928L14.6881 13.2738L17.4471 11.8943C17.7993 11.7182 18.0154 11.3518 17.999 10.9583C17.9827 10.5649 17.7369 10.2176 17.3713 10.0714L7.3713 6.07139Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpCursorClickIcon",
});
</script>
