import {useRouteStoreFn} from "@ntprsrf-frontend/common/src/stores/route.store";
import { CompanyModel } from "@ntprsrf-frontend/service/src/models/intranet/company.model";
import { getCompanyDetailsByUEN } from "@ntprsrf-frontend/service/src/services/intranet/company.service";
import { map } from "rxjs";

export default useRouteStoreFn<CompanyModel>({
    storeName: 'company',
    getNavInfo: (res) => ({
        title: res.companyName,
        subtitle: '',
        navTitle: res.companyName
    }),
    getInfoFromRoute: (route) => {
        const companyUEN = route.params.companyUEN ? route.params.companyUEN : route.params.otherCompanyUEN;
        return getCompanyDetailsByUEN(companyUEN.toString()).pipe(
            map(res => res.data)
        )
    }
})