/// <reference types="vite/types/importMeta" />
import i18next from "i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import enFallbackContent from '../assets/locales/en.json';


const bundledResources = {
  en: {
    translation: enFallbackContent
  }
};

export const i18nextInitialized = i18next
  .use(ChainedBackend)
  .init({
    lng: "en",
    // resources: bundledResources,
    fallbackLng: "en",
    // ... your i18next config
    backend: {
      backends: [
        HttpBackend,
        resourcesToBackend(bundledResources)
      ],
      backendOptions: [{
        loadPath: import.meta.env.VITE_LOCALE_PATH as string + '{{lng}}.json'
      }]
    }
  });