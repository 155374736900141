<template>
  <svg
    class="NtpIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 21L19.5528 21.8944C19.8628 22.0494 20.2309 22.0329 20.5257 21.8507C20.8205 21.6684 21 21.3466 21 21H20ZM4 21H3C3 21.3466 3.17945 21.6684 3.47427 21.8507C3.76909 22.0329 4.13723 22.0494 4.44721 21.8944L4 21ZM12 21L11.5528 21.8944C11.8343 22.0352 12.1657 22.0352 12.4472 21.8944L12 21ZM8 19L8.44721 18.1056C8.16569 17.9648 7.83431 17.9648 7.55279 18.1056L8 19ZM16 19L16.4472 18.1056C16.1657 17.9648 15.8343 17.9648 15.5528 18.1056L16 19ZM15 15C15 15.5523 15.4477 16 16 16C16.5523 16 17 15.5523 17 15H15ZM8 10L7.29289 9.29289C6.90237 9.68342 6.90237 10.3166 7.29289 10.7071L8 10ZM10.2929 13.7071C10.6834 14.0976 11.3166 14.0976 11.7071 13.7071C12.0976 13.3166 12.0976 12.6834 11.7071 12.2929L10.2929 13.7071ZM11.7071 7.70711C12.0976 7.31658 12.0976 6.68342 11.7071 6.29289C11.3166 5.90237 10.6834 5.90237 10.2929 6.29289L11.7071 7.70711ZM6 4H18V2H6V4ZM19 5V21H21V5H19ZM5 21V5H3V21H5ZM12.4472 20.1056L8.44721 18.1056L7.55279 19.8944L11.5528 21.8944L12.4472 20.1056ZM7.55279 18.1056L3.55279 20.1056L4.44721 21.8944L8.44721 19.8944L7.55279 18.1056ZM20.4472 20.1056L16.4472 18.1056L15.5528 19.8944L19.5528 21.8944L20.4472 20.1056ZM15.5528 18.1056L11.5528 20.1056L12.4472 21.8944L16.4472 19.8944L15.5528 18.1056ZM18 4C18.5523 4 19 4.44772 19 5H21C21 3.34315 19.6569 2 18 2V4ZM6 2C4.34315 2 3 3.34315 3 5H5C5 4.44772 5.44772 4 6 4V2ZM17 15V14H15V15H17ZM12 9H8V11H12V9ZM17 14C17 11.2386 14.7614 9 12 9V11C13.6569 11 15 12.3431 15 14H17ZM11.7071 12.2929L8.70711 9.29289L7.29289 10.7071L10.2929 13.7071L11.7071 12.2929ZM8.70711 10.7071L11.7071 7.70711L10.2929 6.29289L7.29289 9.29289L8.70711 10.7071Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpReceiptRefundIcon",
});
</script>
