import { RouteLocationNormalized } from 'vue-router';
import useAuthStore from '../stores/auth.store';

export function redirectToLogin(to : RouteLocationNormalized) : boolean {
  const authStore = useAuthStore();
  if (!authStore.isLogin && !to.meta.ignoresAuth)
    return true;

  return false;
}
