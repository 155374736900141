/// <reference types="vite/types/importMeta" />
import { apiPathFn } from "@ntprsrf-frontend/common/src/utils/api";
import Axios from "axios-observable";
import { map } from "rxjs";
import { HEALTHCHECK_STATUS } from "../../constants/actuator.constants";
import {
  CreateFileShareRequestRequest,
  CurrentFileShareRequest,
  IncomingFileShareRequest,
  OutgoingFileShareRequest,
} from "../../requests/intranet/fileShare.request";
import { HealthCheckResponse } from "../../responses/base.response";
import {
  ApproveFileShareRequestResponse,
  CreateFileShareRequestResponse,
  ListFileShareRequestResponse,
  RevokeFileShareRequestResponse,
  RejectFileShareRequestResponse,
  CurrentShareRequestResponse,
} from "../../responses/intranet/fileShare.response";

const apiPath = apiPathFn(
  import.meta.env.VITE_INTRANET_FILESHAREREQUEST_SERVICE
);

export function healthcheck() {
  return Axios.get<HealthCheckResponse>(apiPath(["actuator", "health"])).pipe(
    map((response) => {
      return response.data.status === HEALTHCHECK_STATUS.UP;
    })
  );
}

export function listFileShareRequests() {
  return Axios.get<ListFileShareRequestResponse>(apiPath([])).pipe(
    map((response) => response.data)
  );
}

export function incomingFileShareRequests(params: IncomingFileShareRequest) {
  return Axios.get<ListFileShareRequestResponse>(apiPath(["incoming"]), {
    params,
  }).pipe(map((response) => response.data));
}

export function outgoingFileShareRequests(params: OutgoingFileShareRequest) {
  return Axios.get<ListFileShareRequestResponse>(apiPath(["outgoing"]), {
    params,
  }).pipe(map((response) => response.data));
}

export function createFileShareRequest(request: CreateFileShareRequestRequest) {
  return Axios.post<CreateFileShareRequestResponse>(apiPath([]), request).pipe(
    map((response) => response.data)
  );
}

export function approveFileShareRequest(fileShareRequestId: string) {
  return Axios.post<ApproveFileShareRequestResponse>(
    apiPath(["approve", fileShareRequestId])
  ).pipe(map((response) => response.data));
}

export function terminateFileShareRequest(
  fileShareRequestId: string,
  reason: string
) {
  return Axios.post<RevokeFileShareRequestResponse>(
    apiPath(["terminate", fileShareRequestId]),
    {
      fsrStatusReason: reason,
    }
  ).pipe(map((response) => response.data));
}

export function unsubscribeFileShareRequest(
  fileShareRequestId: string,
  reason: string
) {
  return Axios.post<RevokeFileShareRequestResponse>(
    apiPath(["unsubscribe", fileShareRequestId]),
    {
      fsrStatusReason: reason,
    }
  ).pipe(map((response) => response.data));
}

export function rejectFileShareRequest(
  fileShareRequestId: string,
  rejectReason: string
) {
  return Axios.post<RejectFileShareRequestResponse>(
    apiPath(["reject", fileShareRequestId]),
    {
      fsrStatusReason: rejectReason,
    }
  ).pipe(map((response) => response.data));
}

export function getFileShareServiceDetail(
  params: Partial<CurrentFileShareRequest>
) {
  return Axios.get<CurrentShareRequestResponse>(apiPath(["current"]), {
    params,
  }).pipe(
    map((response) => {
      if (response?.data?.data)
        return response.data.data;
      else throw new Error("Response Error");
    })
  );
}

export function getFileShareRequestStatus(params: Partial<CurrentFileShareRequest>) {
  return Axios.get<CurrentShareRequestResponse>(apiPath(["fsr-details"]), {
    params,
  }).pipe(
    map((response) => {
      if (response?.data?.data)
        return response.data.data;
      else throw new Error("Response Error");
    })
  );
}
