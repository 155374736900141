<template>
  <svg
    class="NtpIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.3163 3.05159C12.8402 3.22624 13.1234 3.79256 12.9487 4.3165L8.94871 16.3165C8.77406 16.8404 8.20774 17.1236 7.68379 16.949C7.15985 16.7743 6.87669 16.208 7.05133 15.684L11.0513 3.68404C11.226 3.1601 11.7923 2.87694 12.3163 3.05159ZM5.70712 6.29316C6.09765 6.68369 6.09765 7.31685 5.70712 7.70738L3.41422 10.0003L5.70712 12.2932C6.09765 12.6837 6.09765 13.3169 5.70712 13.7074C5.3166 14.0979 4.68343 14.0979 4.2929 13.7074L1.29289 10.7074C0.902369 10.3169 0.902369 9.68369 1.29289 9.29316L4.2929 6.29316C4.68343 5.90264 5.3166 5.90264 5.70712 6.29316ZM14.2929 6.29316C14.6835 5.90264 15.3166 5.90264 15.7072 6.29316L18.7072 9.29316C19.0977 9.68369 19.0977 10.3169 18.7072 10.7074L15.7072 13.7074C15.3166 14.0979 14.6835 14.0979 14.2929 13.7074C13.9024 13.3169 13.9024 12.6837 14.2929 12.2932L16.5858 10.0003L14.2929 7.70738C13.9024 7.31685 13.9024 6.68369 14.2929 6.29316Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpCodeIcon",
});
</script>
