<template>
  <svg
    class="NtpIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.25706 3.09956C9.02167 1.74025 10.9788 1.74025 11.7434 3.09956L17.3237 13.0201C18.0736 14.3533 17.1102 16.0006 15.5805 16.0006H4.4199C2.89025 16.0006 1.92682 14.3533 2.67675 13.0201L8.25706 3.09956ZM11.0001 13.0007C11.0001 13.553 10.5524 14.0007 10.0001 14.0007C9.44784 14.0007 9.00012 13.553 9.00012 13.0007C9.00012 12.4484 9.44784 12.0007 10.0001 12.0007C10.5524 12.0007 11.0001 12.4484 11.0001 13.0007ZM10.0001 5.00073C9.44784 5.00073 9.00012 5.44845 9.00012 6.00073V9.00073C9.00012 9.55302 9.44784 10.0007 10.0001 10.0007C10.5524 10.0007 11.0001 9.55302 11.0001 9.00073V6.00073C11.0001 5.44845 10.5524 5.00073 10.0001 5.00073Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpExclamationIcon",
});
</script>
