<template>
  <svg
    class="NtpIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.3542 15.3549L21.2821 15.7277C21.4314 15.356 21.3446 14.931 21.0613 14.6478C20.778 14.3645 20.353 14.2776 19.9813 14.427L20.3542 15.3549ZM8.64581 3.64648L9.57369 4.01937C9.72307 3.64765 9.6362 3.22265 9.35292 2.93938C9.06964 2.6561 8.64465 2.56922 8.27293 2.71861L8.64581 3.64648ZM19.9813 14.427C19.0613 14.7967 18.0557 15.0007 17 15.0007V17.0007C18.3155 17.0007 19.5739 16.7461 20.7271 16.2827L19.9813 14.427ZM17 15.0007C12.5817 15.0007 9 11.419 9 7.00067H7C7 12.5235 11.4772 17.0007 17 17.0007V15.0007ZM9 7.00067C9 5.94499 9.20396 4.9394 9.57369 4.01937L7.71794 3.2736C7.25452 4.42673 7 5.68521 7 7.00067H9ZM4 12.0007C4 8.63781 6.07526 5.75725 9.0187 4.57436L8.27293 2.71861C4.59771 4.19557 2 7.79355 2 12.0007H4ZM12 20.0007C7.58172 20.0007 4 16.419 4 12.0007H2C2 17.5235 6.47715 22.0007 12 22.0007V20.0007ZM19.4263 14.982C18.2434 17.9254 15.3629 20.0007 12 20.0007V22.0007C16.2071 22.0007 19.8051 19.403 21.2821 15.7277L19.4263 14.982Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpMoonIcon",
});
</script>
