<template>
  <svg
    class="NtpIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0002 2C10.5525 2 11.0002 2.44772 11.0002 3V4.32297L14.9547 5.90474L16.553 5.10557C17.047 4.85858 17.6477 5.05881 17.8946 5.55279C18.1416 6.04676 17.9414 6.64744 17.4474 6.89443L16.2143 7.51101L17.9524 12.9307C18.073 13.3065 17.9612 13.718 17.6671 13.9812C16.9601 14.614 16.0241 15 15.0002 15C13.9764 15 13.0403 14.614 12.3333 13.9812C12.0392 13.718 11.9275 13.3065 12.048 12.9307L13.7633 7.58227L11.0002 6.47703V16H13.0002C13.5525 16 14.0002 16.4477 14.0002 17C14.0002 17.5523 13.5525 18 13.0002 18H7.00022C6.44793 18 6.00022 17.5523 6.00022 17C6.00022 16.4477 6.44793 16 7.00022 16H9.00022V6.47703L6.23713 7.58227L7.95245 12.9307C8.07297 13.3065 7.9612 13.718 7.66713 13.9812C6.96012 14.614 6.02405 15 5.00022 15C3.97639 15 3.04032 14.614 2.33331 13.9812C2.03924 13.718 1.92747 13.3065 2.04799 12.9307L3.78616 7.51101L2.55301 6.89443C2.05903 6.64744 1.8588 6.04676 2.10579 5.55279C2.35278 5.05881 2.95345 4.85858 3.44743 5.10557L5.04578 5.90474L9.00022 4.32297V3C9.00022 2.44772 9.44793 2 10.0002 2ZM5.00022 10.2745L4.18198 12.8258C4.43157 12.9378 4.70825 13 5.00022 13C5.29219 13 5.56887 12.9378 5.81846 12.8258L5.00022 10.2745ZM15.0002 10.2745L14.182 12.8258C14.4316 12.9378 14.7082 13 15.0002 13C15.2922 13 15.5689 12.9378 15.8185 12.8258L15.0002 10.2745Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpScaleIcon",
});
</script>
