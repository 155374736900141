<template>
  <svg
    class="NtpIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 4C13 3.44772 12.5523 3 12 3C11.4477 3 11 3.44772 11 4H13ZM11 5C11 5.55228 11.4477 6 12 6C12.5523 6 13 5.55228 13 5H11ZM18 15C17.4477 15 17 15.4477 17 16C17 16.5523 17.4477 17 18 17V15ZM20 17C20.5523 17 21 16.5523 21 16C21 15.4477 20.5523 15 20 15V17ZM12 16V15C11.4477 15 11 15.4477 11 16H12ZM14 17C14.5523 17 15 16.5523 15 16C15 15.4477 14.5523 15 14 15V17ZM11 20C11 20.5523 11.4477 21 12 21C12.5523 21 13 20.5523 13 20H11ZM13 9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9H13ZM12 12H11C11 12.5523 11.4477 13 12 13V12ZM16 19C15.4477 19 15 19.4477 15 20C15 20.5523 15.4477 21 16 21V19ZM20 21C20.5523 21 21 20.5523 21 20C21 19.4477 20.5523 19 20 19V21ZM4 11C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13V11ZM8 13C8.55228 13 9 12.5523 9 12C9 11.4477 8.55228 11 8 11V13ZM12.01 13C12.5623 13 13.01 12.5523 13.01 12C13.01 11.4477 12.5623 11 12.01 11V13ZM16.01 13C16.5623 13 17.01 12.5523 17.01 12C17.01 11.4477 16.5623 11 16.01 11V13ZM20 11C19.4477 11 19 11.4477 19 12C19 12.5523 19.4477 13 20 13V11ZM20.01 13C20.5623 13 21.01 12.5523 21.01 12C21.01 11.4477 20.5623 11 20.01 11V13ZM5 5H7V3H5V5ZM7 5V7H9V5H7ZM7 7H5V9H7V7ZM5 7V5H3V7H5ZM5 7H3C3 8.10457 3.89543 9 5 9V7ZM7 7V9C8.10457 9 9 8.10457 9 7H7ZM7 5H9C9 3.89543 8.10457 3 7 3V5ZM5 3C3.89543 3 3 3.89543 3 5H5V3ZM17 5H19V3H17V5ZM19 5V7H21V5H19ZM19 7H17V9H19V7ZM17 7V5H15V7H17ZM17 7H15C15 8.10457 15.8954 9 17 9V7ZM19 7V9C20.1046 9 21 8.10457 21 7H19ZM19 5H21C21 3.89543 20.1046 3 19 3V5ZM17 3C15.8954 3 15 3.89543 15 5H17V3ZM5 17H7V15H5V17ZM7 17V19H9V17H7ZM7 19H5V21H7V19ZM5 19V17H3V19H5ZM5 19H3C3 20.1046 3.89543 21 5 21V19ZM7 19V21C8.10457 21 9 20.1046 9 19H7ZM7 17H9C9 15.8954 8.10457 15 7 15V17ZM5 15C3.89543 15 3 15.8954 3 17H5V15ZM11 4V5H13V4H11ZM18 17H20V15H18V17ZM12 17H14V15H12V17ZM11 16V20H13V16H11ZM11 9V12H13V9H11ZM16 21H20V19H16V21ZM4 13H8V11H4V13ZM12 13H12.01V11H12V13ZM20 13H20.01V11H20V13ZM12 13H16.01V11H12V13Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpQrcodeIcon",
});
</script>
