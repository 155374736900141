<template>
  <svg
    class="NtpIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.2929 3.29289L12.5858 4L12.5858 4L13.2929 3.29289ZM18.7071 8.70711L19.4142 8L18.7071 8.70711ZM10 20C9.44772 20 9 20.4477 9 21C9 21.5523 9.44772 22 10 22V20ZM4 16C4 16.5523 4.44772 17 5 17C5.55228 17 6 16.5523 6 16H4ZM4.29289 20.2929C3.90237 20.6834 3.90237 21.3166 4.29289 21.7071C4.68342 22.0976 5.31658 22.0976 5.70711 21.7071L4.29289 20.2929ZM7 4H12.5858V2H7V4ZM18 9.41421V19H20V9.41421H18ZM12.5858 4L18 9.41421L19.4142 8L14 2.58579L12.5858 4ZM20 9.41421C20 8.88378 19.7893 8.37507 19.4142 8L18 9.41421H18H20ZM12.5858 4V4L14 2.58579C13.6249 2.21071 13.1162 2 12.5858 2V4ZM17 22C18.6569 22 20 20.6569 20 19H18C18 19.5523 17.5523 20 17 20V22ZM6 5C6 4.44772 6.44772 4 7 4V2C5.34315 2 4 3.34315 4 5H6ZM17 20H10V22H17V20ZM6 16V5H4V16H6ZM14 14C14 15.1046 13.1046 16 12 16V18C14.2091 18 16 16.2091 16 14H14ZM10 14C10 12.8954 10.8954 12 12 12V10C9.79086 10 8 11.7909 8 14H10ZM12 12C13.1046 12 14 12.8954 14 14H16C16 11.7909 14.2091 10 12 10V12ZM9.17157 15.4142L4.29289 20.2929L5.70711 21.7071L10.5858 16.8284L9.17157 15.4142ZM12 16C11.4474 16 10.9488 15.7772 10.5858 15.4142L9.17157 16.8284C9.89434 17.5512 10.8957 18 12 18V16ZM10.5858 15.4142C10.2228 15.0512 10 14.5526 10 14H8C8 15.1043 8.44881 16.1057 9.17157 16.8284L10.5858 15.4142Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpDocumentSearchIcon",
});
</script>
