<template>
  <svg
    class="NtpIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13V11ZM15 13C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11V13ZM12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17V15ZM15 17C15.5523 17 16 16.5523 16 16C16 15.4477 15.5523 15 15 15V17ZM9 11C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13V11ZM9.01 13C9.56228 13 10.01 12.5523 10.01 12C10.01 11.4477 9.56228 11 9.01 11V13ZM9 15C8.44772 15 8 15.4477 8 16C8 16.5523 8.44772 17 9 17V15ZM9.01 17C9.56228 17 10.01 16.5523 10.01 16C10.01 15.4477 9.56228 15 9.01 15V17ZM18 7V19H20V7H18ZM17 20H7V22H17V20ZM6 19V7H4V19H6ZM7 6H9V4H7V6ZM15 6H17V4H15V6ZM7 20C6.44772 20 6 19.5523 6 19H4C4 20.6569 5.34315 22 7 22V20ZM18 19C18 19.5523 17.5523 20 17 20V22C18.6569 22 20 20.6569 20 19H18ZM20 7C20 5.34315 18.6569 4 17 4V6C17.5523 6 18 6.44772 18 7H20ZM6 7C6 6.44772 6.44772 6 7 6V4C5.34315 4 4 5.34315 4 7H6ZM12 13H15V11H12V13ZM12 17H15V15H12V17ZM11 4H13V2H11V4ZM13 6H11V8H13V6ZM11 6C10.4477 6 10 5.55228 10 5H8C8 6.65685 9.34315 8 11 8V6ZM14 5C14 5.55228 13.5523 6 13 6V8C14.6569 8 16 6.65685 16 5H14ZM13 4C13.5523 4 14 4.44772 14 5H16C16 3.34315 14.6569 2 13 2V4ZM11 2C9.34315 2 8 3.34315 8 5H10C10 4.44772 10.4477 4 11 4V2ZM9 13H9.01V11H9V13ZM9 17H9.01V15H9V17Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpClipboardListIcon",
});
</script>
