export const sharedByMe = 'shared-by-me';
export const shareFilesWith = 'share-files-with';
export const sharedWithMe = 'shared-with-me';
export const shareFilesBy = 'files-shared-by';

export type RequestShareFileType = typeof sharedByMe | typeof sharedWithMe;
export type ParamShareFileType = typeof shareFilesWith | typeof shareFilesBy;

export const ShareFileTypeMap: Record<ParamShareFileType, RequestShareFileType> = {
    [shareFilesBy]: sharedWithMe,
    [shareFilesWith]: sharedByMe
}