<template>
  <svg
    class="NtpIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.6179 5.98336C20.4132 5.99374 20.2072 5.999 20 5.999C16.9265 5.999 14.123 4.84356 11.9999 2.94336C9.87691 4.84348 7.07339 5.99887 4 5.99887C3.79277 5.99887 3.58678 5.99362 3.38213 5.98324C3.1327 6.94685 3 7.95745 3 8.99903C3 14.5906 6.82432 19.2889 12 20.621C17.1757 19.2889 21 14.5906 21 8.99903C21 7.95749 20.8673 6.94694 20.6179 5.98336Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 9V11M12 15H12.01"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpShieldExclamationIcon",
});
</script>
