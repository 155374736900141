import { defineStore, createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

export const useVersionStore = defineStore("x-ntp-version", {
  persist: true,
  state: () => ({
    version: "",
  }),
  actions: {
    setVersion: async function (version: string) {
      this.version = version;
    },
  },
});
