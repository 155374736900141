/// <reference types="vite/types/importMeta" />
import { apiPathFn } from "@ntprsrf-frontend/common/src/utils/api";
import { Axios } from "axios-observable";
import { map } from "rxjs";
import {
  CreateCompanyRequest,
  ListCompanyRequest,
  ListCompanyActivityRequest,
  UpdateCompanyRequest,
  UpdateCompanySettingRequest,
} from "../../requests/intranet/company.request";
import {
  CompanyDetailResponse,
  CreateCompanyResponse,
  DeleteCompanyResponse,
  GetCompanySettingResponse,
  ListCompanyActivityResponse,
  ListCompanyResponse,
  UpdateCompanySettingResponse,
  UpdateComponayResponse,
} from "../../responses/intranet/company.response";

const apiPath = apiPathFn(import.meta.env.VITE_INTRANET_COMPANY_SERVICE);

export function listCompany(params: Partial<ListCompanyRequest>) {
  return Axios.get<ListCompanyResponse>(apiPath(["list"]), {
    params: {
      ...params,
      eGuaranteeTypes: params.eGuaranteeTypes
        ? params.eGuaranteeTypes.join(",")
        : undefined,
      searchEGuaranteeType: params.searchEGuaranteeType,
    },
  }).pipe(map((response) => response.data));
}

export function getCompanyDetails(companyId: string) {
  return Axios.get<CompanyDetailResponse>(apiPath([companyId])).pipe(
    map((response) => response.data)
  );
}

export function getCompanyDetailsByUEN(companyUEN: string) {
  return Axios.post<CompanyDetailResponse>(apiPath(["UEN", companyUEN])).pipe(
    map((response) => response.data)
  );
}

export function createCompany(request: CreateCompanyRequest) {
  return Axios.post<CreateCompanyResponse>(apiPath(["create"]), request).pipe(
    map((response) => response.data)
  );
}

export function updateCompany(url: string, request: UpdateCompanyRequest) {
  return Axios.post<UpdateComponayResponse>(url, request).pipe(
    map((response) => response.data)
  );
}

export function deleteCompany(companyId: string) {
  return Axios.delete<DeleteCompanyResponse>(apiPath([companyId])).pipe(
    map((response) => response.data)
  );
}

export function getListCompanyActivity(
  companyId: string,
  params: Partial<ListCompanyActivityRequest>
) {
  return Axios.get<ListCompanyActivityResponse>(
    apiPath(["activity", companyId]),
    {
      params,
    }
  ).pipe(map((response) => response.data));
}

export function getCompanySetting() {
  return Axios.get<GetCompanySettingResponse>(apiPath(["setting"])).pipe(
    map((response) => {
      if (response?.data?.data)
        return response.data.data;
      else throw new Error("Response Error");
    })
  );
}

export function updateCompanySetting(data: UpdateCompanySettingRequest) {
  return Axios.put<UpdateCompanySettingResponse>(
    apiPath(["setting"]),
    data
  ).pipe(
    map((response) => {
      if (response?.data?.data)
        return response.data.data;
      else throw new Error("Response Error");
    })
  );
}
