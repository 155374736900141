<template>
  <svg
    class="NtpIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.29289 15.2929C6.90237 15.6834 6.90237 16.3166 7.29289 16.7071C7.68342 17.0976 8.31658 17.0976 8.70711 16.7071L7.29289 15.2929ZM15 11C15 12.1046 14.1046 13 13 13V15C15.2091 15 17 13.2091 17 11H15ZM11 11C11 9.89543 11.8954 9 13 9V7C10.7909 7 9 8.79086 9 11H11ZM13 9C14.1046 9 15 9.89543 15 11H17C17 8.79086 15.2091 7 13 7V9ZM10.1716 12.4142L7.29289 15.2929L8.70711 16.7071L11.5858 13.8284L10.1716 12.4142ZM13 13C12.4474 13 11.9488 12.7772 11.5858 12.4142L10.1716 13.8284C10.8943 14.5512 11.8957 15 13 15V13ZM11.5858 12.4142C11.2228 12.0512 11 11.5526 11 11H9C9 12.1043 9.44881 13.1057 10.1716 13.8284L11.5858 12.4142ZM20 12C20 16.4183 16.4183 20 12 20V22C17.5228 22 22 17.5228 22 12H20ZM12 20C7.58172 20 4 16.4183 4 12H2C2 17.5228 6.47715 22 12 22V20ZM4 12C4 7.58172 7.58172 4 12 4V2C6.47715 2 2 6.47715 2 12H4ZM12 4C16.4183 4 20 7.58172 20 12H22C22 6.47715 17.5228 2 12 2V4Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpSearchCircleIcon",
});
</script>
