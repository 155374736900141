<template>
  <svg
    class="NtpIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.067 11.1999C11.5337 11.5999 11.5337 12.3999 12.067 12.7999L17.4003 16.7999C18.0596 17.2943 19.0003 16.8239 19.0003 15.9999V7.99986C19.0003 7.17582 18.0596 6.70544 17.4003 7.19986L12.067 11.1999Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.06699 11.1999C3.53366 11.5999 3.53366 12.3999 4.06699 12.7999L9.40033 16.7999C10.0596 17.2943 11.0003 16.8239 11.0003 15.9999V7.99986C11.0003 7.17582 10.0596 6.70544 9.40033 7.19986L4.06699 11.1999Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpRewindIcon",
});
</script>
