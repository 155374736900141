<template>
  <svg
    class="NtpIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.05025 3.63664C5.44078 4.02717 5.44078 4.66033 5.05025 5.05086C2.31658 7.78453 2.31658 12.2167 5.05025 14.9504C5.44078 15.3409 5.44078 15.974 5.05025 16.3646C4.65973 16.7551 4.02656 16.7551 3.63604 16.3646C0.12132 12.8498 0.12132 7.15136 3.63604 3.63664C4.02656 3.24612 4.65973 3.24612 5.05025 3.63664ZM14.9498 3.63688C15.3403 3.24635 15.9735 3.24635 16.364 3.63688C19.8787 7.15159 19.8787 12.8501 16.364 16.3648C15.9735 16.7553 15.3403 16.7553 14.9498 16.3648C14.5592 15.9743 14.5592 15.3411 14.9498 14.9506C17.6834 12.2169 17.6834 7.78476 14.9498 5.05109C14.5592 4.66056 14.5592 4.0274 14.9498 3.63688ZM7.87869 6.46507C8.26921 6.8556 8.26921 7.48876 7.87869 7.87928C6.70711 9.05086 6.70711 10.9504 7.87869 12.1219C8.26921 12.5124 8.26921 13.1456 7.87868 13.5361C7.48816 13.9267 6.855 13.9267 6.46447 13.5361C4.51185 11.5835 4.51185 8.41769 6.46447 6.46507C6.855 6.07455 7.48816 6.07455 7.87869 6.46507ZM12.1213 6.4653C12.5119 6.07478 13.145 6.07478 13.5355 6.4653C15.4882 8.41792 15.4882 11.5837 13.5355 13.5364C13.145 13.9269 12.5119 13.9269 12.1213 13.5364C11.7308 13.1458 11.7308 12.5127 12.1213 12.1222C13.2929 10.9506 13.2929 9.05109 12.1213 7.87952C11.7308 7.48899 11.7308 6.85583 12.1213 6.4653ZM10 9.00084C10.5523 9.00084 11 9.44855 11 10.0008V10.0108C11 10.5631 10.5523 11.0108 10 11.0108C9.44772 11.0108 9 10.5631 9 10.0108V10.0008C9 9.44855 9.44772 9.00084 10 9.00084Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpStatusOnlineIcon",
});
</script>
