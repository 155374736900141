<template>
  <svg
    class="NtpIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.99999 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 9.99999 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 9.99999 18ZM13.7071 9.29289L10.7071 6.29289C10.3166 5.90237 9.68341 5.90237 9.29289 6.29289C8.90236 6.68342 8.90236 7.31658 9.29289 7.70711L10.5858 9L7 9C6.44771 9 6 9.44771 6 10C6 10.5523 6.44771 11 7 11H10.5858L9.29289 12.2929C8.90236 12.6834 8.90236 13.3166 9.29289 13.7071C9.68341 14.0976 10.3166 14.0976 10.7071 13.7071L13.7071 10.7071C14.0976 10.3166 14.0976 9.68342 13.7071 9.29289Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpArrowCircleRightIcon",
});
</script>
