<template>
  <svg
    class="NtpIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.29315 9.70679C5.10545 9.51926 5 9.26495 5 8.99979C5 8.73462 5.10545 8.48031 5.29315 8.29279L9.29816 4.29279C9.48592 4.10532 9.74055 4 10.006 4C10.2715 4 10.5262 4.10532 10.7139 4.29279L14.7189 8.29279C14.9013 8.48139 15.0022 8.73399 15 8.99619C14.9977 9.25838 14.8924 9.5092 14.7067 9.6946C14.5211 9.88001 14.27 9.98518 14.0074 9.98746C13.7449 9.98974 13.492 9.88894 13.3032 9.70679L11.0073 7.41379V14.9998C11.0073 15.265 10.9018 15.5194 10.714 15.7069C10.5263 15.8944 10.2716 15.9998 10.006 15.9998C9.7405 15.9998 9.48582 15.8944 9.29805 15.7069C9.11028 15.5194 9.00479 15.265 9.00479 14.9998V7.41379L6.70892 9.70679C6.52116 9.89426 6.26653 9.99957 6.00104 9.99957C5.73554 9.99957 5.48092 9.89426 5.29315 9.70679Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpArrowNarrowShortUpIcon",
});
</script>
