import { RouteRecordRaw } from "vue-router";
import { eguaranteeTypes } from "@ntprsrf-frontend/internet/src/constants/APIEnums";

const settingsRoutes: RouteRecordRaw[] = [
  {
    component: () => import("@/views/Settings/Settings.vue"),
    path: "settings",
    name: "SETTINGS",
    meta: {
      label: "Settings",
      eguaranteeTypesCanAccess: [
        eguaranteeTypes.NORMAL.VALUE,
        eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
        eguaranteeTypes.GOVERNMENT_AGENCY.VALUE,
      ],

    },
  },
];

export default settingsRoutes;
