<template>
  <svg
    class="NtpIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.7071 12.2929C15.0977 12.6834 15.0977 13.3166 14.7071 13.7071L10.7071 17.7071C10.3166 18.0976 9.68343 18.0976 9.2929 17.7071L5.29289 13.7071C4.90237 13.3166 4.90237 12.6834 5.29289 12.2929C5.68342 11.9024 6.31659 11.9024 6.70711 12.2929L9.00001 14.5858L9.00001 3C9.00001 2.44772 9.44773 2 10 2C10.5523 2 11 2.44772 11 3L11 14.5858L13.2929 12.2929C13.6834 11.9024 14.3166 11.9024 14.7071 12.2929Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpArrowNarrowDownIcon",
});
</script>
