import { RouteRecordRaw } from "vue-router";
import { eguaranteeTypes } from "@ntprsrf-frontend/internet/src/constants/APIEnums";

const taskNotificationRoute: RouteRecordRaw[] = [
  {
    path: "notifications",
    name: "NOTIFICATIONS",
    redirect: (to) => {
      return {
        name: "NOTIFICATIONS_DETAIL",
        params: {
          notificationTabType: "notification",
        },
      };
    },
    children: [
      {
        name: "NOTIFICATIONS_DETAIL",
        path: ":notificationTabType",
        meta: {
          label: "Notifications",
          eguaranteeTypesCanAccess: [
            eguaranteeTypes.NORMAL.VALUE,
            eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
            eguaranteeTypes.GOVERNMENT_AGENCY.VALUE,
          ],
        },
        component: () => import("@/views/Notifications.vue"),
      },
    ],
  },
];

export default taskNotificationRoute;
