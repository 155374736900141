<template>
  <svg
    class="NtpIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9333 12.7999C12.4667 12.3999 12.4667 11.5999 11.9333 11.1999L6.6 7.19986C5.94076 6.70544 5 7.17582 5 7.99986L5 15.9999C5 16.8239 5.94076 17.2943 6.6 16.7999L11.9333 12.7999Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.9333 12.7999C20.4667 12.3999 20.4667 11.5999 19.9333 11.1999L14.6 7.19986C13.9408 6.70544 13 7.17582 13 7.99986L13 15.9999C13 16.8239 13.9408 17.2943 14.6 16.7999L19.9333 12.7999Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpFastForwardIcon",
});
</script>
