<template>
  <svg
    class="NtpIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.00073 3L6.58989 6.58916M21.0007 21L17.4119 17.4112M13.8756 18.8246C13.2684 18.9398 12.6419 19 12.0012 19C7.52354 19 3.73324 16.0571 2.45898 12C2.80588 10.8955 3.33924 9.87361 4.02217 8.97118M9.87941 9.87868C10.4223 9.33579 11.1723 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12C15.0007 12.8284 14.6649 13.5784 14.1221 14.1213M9.87941 9.87868L14.1221 14.1213M9.87941 9.87868L6.58989 6.58916M14.1221 14.1213L6.58989 6.58916M14.1221 14.1213L17.4119 17.4112M6.58989 6.58916C8.14971 5.58354 10.0073 5 12.0012 5C16.4788 5 20.2691 7.94291 21.5434 12C20.8365 14.2507 19.3553 16.1585 17.4119 17.4112"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpEyeOffIcon",
});
</script>
