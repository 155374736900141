<template>
  <svg
    class="NtpIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.16611 4.99976C5.17437 4.95895 7.91528 3.81119 10 1.94531C12.0847 3.81119 14.8256 4.95895 17.8339 4.99976C17.9431 5.65053 18 6.31907 18 7.00089C18 12.2257 14.6608 16.6707 10 18.318C5.33923 16.6707 2 12.2257 2 7.00089C2 6.31907 2.05686 5.65053 2.16611 4.99976ZM13.7071 8.70796C14.0976 8.31744 14.0976 7.68427 13.7071 7.29375C13.3166 6.90322 12.6834 6.90322 12.2929 7.29375L9 10.5866L7.70711 9.29375C7.31658 8.90322 6.68342 8.90322 6.29289 9.29375C5.90237 9.68427 5.90237 10.3174 6.29289 10.708L8.29289 12.708C8.68342 13.0985 9.31658 13.0985 9.70711 12.708L13.7071 8.70796Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpShieldCheckIcon",
});
</script>
