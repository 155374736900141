import { createRouter, createWebHashHistory } from "vue-router";
import { redirectToLogin } from "./handlerAuthentication";
import { canUserAccess, isUserFirstLogin } from "./helpers/authenticateRoute";
import dataSettingsRoute from "./dataSettings.route";
import fileSharingRoute from "./fileSharing.route";
import notificationsRoute from "./notifications.route";
import handleBreadcrumb from "./handleBreadcrumb";
import settingsRoutes from "./settings.route";
import governmentServiceRoute from "./governmentServices.route";
import { eguaranteeTypes } from "@ntprsrf-frontend/internet/src/constants/APIEnums";
import { corppassLogin } from "@ntprsrf-frontend/common/src/utils/corppass";
import useAuthStore from "../stores/auth.store";
import useCompanyStore from "@ntprsrf-frontend/internet/src/stores/company.store";
import useTimerStore from "@ntprsrf-frontend/internet/src/stores/timer.store";

export const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/redirect/:path",
      name: "REDIRECT",
      meta: {
        ignoresAuth: true,
      },
      component: () => import("@/views/Redirect.vue"),
    },
    {
      path: "/login",
      name: "LOGIN",
      meta: {
        label: "login",
        ignoresAuth: true,
        eguaranteeTypesCanAccess: [
          eguaranteeTypes.NORMAL.VALUE,
          eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
          eguaranteeTypes.GOVERNMENT_AGENCY.VALUE,
        ],
      },
      component: () => import("@/views/Login.vue"),
    },
    // TODO: Removed for prod purpose
    // {
    //   path: "/login-test",
    //   name: "LOGIN_TEST",
    //   meta: {
    //     label: "LoginTest",
    //     ignoresAuth: true,
    //     eguaranteeTypesCanAccess: [
    //       eguaranteeTypes.NORMAL.VALUE,
    //       eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
    //       eguaranteeTypes.GOVERNMENT_AGENCY.VALUE,
    //     ],
    //   },
    //   component: () => import("@/views/LoginTest.vue"),
    // },
    {
      path: "/400",
      name: "400_REDIRECTERROR",
      meta: {
        label: "400 Redirect Error",
        ignoresAuth: true,
        eguaranteeTypesCanAccess: [
          eguaranteeTypes.NORMAL.VALUE,
          eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
          eguaranteeTypes.GOVERNMENT_AGENCY.VALUE,
        ],
      },
      component: () => import("@/views/400_RedirectError.vue"),
    },
    {
      path: "/",
      name: "HOME PAGE",
      meta: {
        label: "HOME PAGE",
        ignoresAuth: true,
        eguaranteeTypesCanAccess: [
          eguaranteeTypes.NORMAL.VALUE,
          eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
          eguaranteeTypes.GOVERNMENT_AGENCY.VALUE,
        ],
      },
      component: () => import("@/views/Homepage.vue"),
    },
    {
      path: "/corppass",
      name: "CORPPASS",
      meta: {
        label: "Corppass Login",
        ignoresAuth: true,
      },
      component: () => import("@/views/Corppass.vue"),
    },
    {
      path: "/",
      name: "HOME",
      component: () => import("@/views/Home.vue"),
      redirect: () => {
        if(
          !useAuthStore().isLogin
        ) {
          return {
            name: "HOME PAGE",
          };
        }
        
        return {
          name: "DASHBOARD",
        };
      },
      meta: {
        isInformationalService: true,
        eguaranteeTypesCanAccess: [
          eguaranteeTypes.NORMAL.VALUE,
          eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
          eguaranteeTypes.GOVERNMENT_AGENCY.VALUE,
        ],
      },
      children: [
        {
          path: "dashboard",
          name: "DASHBOARD",
          component: () => import("@/views/Dashboard.vue"),
          meta: {
            eguaranteeTypesCanAccess: [
              eguaranteeTypes.NORMAL.VALUE,
              eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
              eguaranteeTypes.GOVERNMENT_AGENCY.VALUE,
            ],
          },
        },
        {
          path: "terms-and-conditions",
          name: "TERMS_AND_CONDITIONS",
          component: () => import("@/views/TermsAndConditions/index.vue"),
          meta: {
            ignoresAuth: true,
            eguaranteeTypesCanAccess: [
              eguaranteeTypes.NORMAL.VALUE,
              eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
              eguaranteeTypes.GOVERNMENT_AGENCY.VALUE,
            ],
          },
        },
        {
          path: "update-profile",
          name: "STARTING_FORM",
          component: () => import("@/views/PreStartingForm.vue"),
          beforeEnter: (_, _2, next) => {
            const authStore = useAuthStore();
            if (!authStore.hasAgreedToTNC) {
              next({
                name: 'TERMS_AND_CONDITIONS'
              })
            }
            else next();
          },
          meta: {
            eguaranteeTypesCanAccess: [
              eguaranteeTypes.NORMAL.VALUE,
              eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
              eguaranteeTypes.GOVERNMENT_AGENCY.VALUE,
            ],
          },
        },
        {
          path: "maintenance",
          name: "MAINTENANCE",
          component: () => import("@/views/Maintenance.vue"),
          meta: {
            ignoresAuth: true,
            eguaranteeTypesCanAccess: [
              eguaranteeTypes.NORMAL.VALUE,
              eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
              eguaranteeTypes.GOVERNMENT_AGENCY.VALUE,
            ],
          },
        },
        {
          path: "timeout",
          name: "TIMEOUT",
          component: () => import("@/views/SessionTimeout.vue"),
          meta: {
            ignoresAuth: true,
          },
        },
        {
          path: "services",
          name: "SERVICES",
          meta: {
            label: "Services",
            eguaranteeTypesCanAccess: [
              eguaranteeTypes.NORMAL.VALUE,
              eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
              eguaranteeTypes.GOVERNMENT_AGENCY.VALUE,
            ],
          },
          component: () => import("@/views/ServiceSubscriptions/Services.vue"),
        },
        {
          path: "",
          name: "DETAIL",
          component: () => import("@/views/_ViewWithBreadcrumb.vue"),
          children: [
            {
              path: "my-profile",
              name: "USER_PROFILE",
              component: () => import("@/views/UserProfile.vue"),
              meta: {
                label: "My Profile",
                eguaranteeTypesCanAccess: [
                  eguaranteeTypes.NORMAL.VALUE,
                  eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
                  eguaranteeTypes.GOVERNMENT_AGENCY.VALUE,
                ],
              },
            },
            
            {
              path: "privacy-statement",
              name: "PRIVACY_STATEMENT",
              component: () => import("@/views/PrivacyStatement.vue"),
              meta: {
                ignoresAuth: true,
                label: "Privacy Statement",
              },
            },
            {
              path: "terms-of-use",
              name: "TERMS_OF_USE",
              component: () => import("@/views/TermsOfUse.vue"),
              meta: {
                ignoresAuth: true,
                label: "Terms of Use",
              },
            },
            {
              path: "contact-us",
              name: "CONTACT_US",
              component: () => import("@/views/ContactUs.vue"),
              meta: {
                ignoresAuth: true,
                label: "Contact Us",
              },
            },
            {
              path: "about-us",
              name: "ABOUT_US",
              component: () => import("@/views/AboutUs.vue"),
              meta: {
                isInformationalService: true,
                ignoresAuth: true,
                label: "About Us",
              },
            },
            {
              path: "sitemap",
              name: "SITEMAP",
              component: () => import("@/views/Sitemap.vue"),
              meta: {
                ignoresAuth: true,
                label: "Sitemap",
              },
            },
            {
              path: "get-started",
              name: "GET_STARTED",
              component: () => import("@/views/GetStarted.vue"),
              meta: {
                isInformationalService: true,
                ignoresAuth: true,
                label: "Get Started",
              },
            },
            ...governmentServiceRoute,
            ...fileSharingRoute,
            ...settingsRoutes,
            ...dataSettingsRoute,
            ...notificationsRoute,
          ],
          meta: {
            eguaranteeTypesCanAccess: [
              eguaranteeTypes.NORMAL.VALUE,
              eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
              eguaranteeTypes.GOVERNMENT_AGENCY.VALUE,
            ],
          },
        },
        {
          path: "/403",
          name: "403_FORBIDDEN",
          meta: {
            label: "403 Forbidden",
            ignoresAuth: true,
            eguaranteeTypesCanAccess: [
              eguaranteeTypes.NORMAL.VALUE,
              eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
              eguaranteeTypes.GOVERNMENT_AGENCY.VALUE,
            ],
          },
          component: () => import("@/views/403_Forbidden.vue"),
        },
        {
          path: "*",
          name: "NOT_FOUND",
          meta: {
            label: "NotFound",
            ignoresAuth: true,
            eguaranteeTypesCanAccess: [
              eguaranteeTypes.NORMAL.VALUE,
              eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
              eguaranteeTypes.GOVERNMENT_AGENCY.VALUE,
            ],
          },
          component: () => import("@/views/NotFound.vue"),
        },
        {
          path: "form",
          name: "FORM_APPLICATIONS",
          meta: {
            label: "Form Applications",
          },
          redirect: {
            name: "MAINTENANCE",
          },
        },
      ],
    },
  ],
});

router.beforeEach(async (to, from) => {
  const ignoresAuth = to.meta.ignoresAuth === true;
  const hasAuth = !redirectToLogin(to);
  const hasAccess = canUserAccess(to);
  const isFirstLogin = isUserFirstLogin(to);
  const isLogin = useAuthStore().isLogin
  const isInformationalService = to.meta.isInformationalService;

  if (to.name == "HOME PAGE" && isLogin) {
    return {
      name: "DASHBOARD",
    };
  }

  if (
    to.name == "LOGIN"
    && !isLogin
  )  {
    corppassLogin("/");
    return false;
  }

  if (!hasAuth) {
    if(to.name === "DASHBOARD") 
      return {
        name: "HOME PAGE",
      };

    corppassLogin(to.fullPath);
    return false;
  }

  if (!ignoresAuth) {
    if (hasAccess === undefined) {
      return {
        name: "NOT_FOUND",
      };
    } else if (!hasAccess) {
      return {
        name: "403_FORBIDDEN",
      };
    }
  }

  if (isFirstLogin) {
    return {
      name: "TERMS_AND_CONDITIONS",
    };
  }

  const timerStore = useTimerStore();
  const store = useCompanyStore();

  if(isInformationalService) {
    // Temprorary removed for UAT
    // gtag('event', 'screen_view', {
    //   'app_name': 'Customs - NTP',
    //   'screen_name': to.name
    // });
  
    if(!from.name) {
      timerStore.from = new Date();
    } else {
      // Temprorary removed for UAT
      // gtag('event', 'page_view_time', {
      //   'app_name': 'Customs - NTP',
      //   'screen_name': from.name,
      //   'from': timerStore.from,
      //   'to': new Date(),
      //   'duration': timerStore.duration,
      //   'durationMillisecs': timerStore.durationInMillisecs
      // });
  
      timerStore.from = new Date();
    }
  }

  if(to.meta.transactionalServiceId) {
    window.wogaaCustom.startTransactionalService(to.meta.transactionalServiceId);
  }

  if(to.meta.isCompanyStore) {
    const companyUEN = to.params.companyUEN ? to.params.companyUEN : to.params.otherCompanyUEN;


    if(companyUEN !== store.detail?.companyUEN) {
      await store.setRouteInfo(to);
    }
  }

  handleBreadcrumb(to);
});

export default router;
