<template>
  <svg
    class="NtpIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.24254 3.03009C9.77833 3.16404 10.1041 3.70698 9.97014 4.24277L9.53078 6.00024H12.4692L13.0299 3.7577C13.1638 3.22191 13.7067 2.89615 14.2425 3.03009C14.7783 3.16404 15.1041 3.70698 14.9701 4.24277L14.5308 6.00024H17C17.5523 6.00024 18 6.44795 18 7.00024C18 7.55252 17.5523 8.00024 17 8.00024H14.0308L13.0308 12.0002H15C15.5523 12.0002 16 12.448 16 13.0002C16 13.5525 15.5523 14.0002 15 14.0002H12.5308L11.9701 16.2428C11.8362 16.7786 11.2933 17.1043 10.7575 16.9704C10.2217 16.8364 9.89591 16.2935 10.0299 15.7577L10.4692 14.0002H7.53078L6.97014 16.2428C6.83619 16.7786 6.29326 17.1043 5.75746 16.9704C5.22167 16.8364 4.89591 16.2935 5.02986 15.7577L5.46922 14.0002H3C2.44772 14.0002 2 13.5525 2 13.0002C2 12.448 2.44772 12.0002 3 12.0002H5.96922L6.96922 8.00024H5C4.44772 8.00024 4 7.55252 4 7.00024C4 6.44795 4.44772 6.00024 5 6.00024H7.46922L8.02986 3.7577C8.16381 3.22191 8.70674 2.89615 9.24254 3.03009ZM9.03078 8.00024L8.03078 12.0002H10.9692L11.9692 8.00024H9.03078Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpHashtagIcon",
});
</script>
