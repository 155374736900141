<template>
  <svg
    class="NtpIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 1.94531C7.91528 3.81119 5.17437 4.95895 2.16611 4.99976C2.05686 5.65053 2 6.31907 2 7.00089C2 12.2257 5.33923 16.6707 10 18.318C14.6608 16.6707 18 12.2257 18 7.00089C18 6.31907 17.9431 5.65053 17.8339 4.99976C14.8256 4.95895 12.0847 3.81119 10 1.94531ZM11 14.0009C11 14.5531 10.5523 15.0009 10 15.0009C9.44772 15.0009 9 14.5531 9 14.0009C9 13.4486 9.44772 13.0009 10 13.0009C10.5523 13.0009 11 13.4486 11 14.0009ZM11 7.00085C11 6.44857 10.5523 6.00085 10 6.00085C9.44772 6.00085 9 6.44857 9 7.00085V10.0009C9 10.5531 9.44772 11.0009 10 11.0009C10.5523 11.0009 11 10.5531 11 10.0009V7.00085Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpShieldExclamationIcon",
});
</script>
