<template>
  <svg
    class="NtpIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.70686 2.29289C3.31634 1.90237 2.68317 1.90237 2.29265 2.29289C1.90212 2.68342 1.90212 3.31658 2.29265 3.70711L9.21401 10.6285C9.26301 10.6906 9.31922 10.7469 9.38139 10.7958L16.2926 17.7071C16.6832 18.0976 17.3163 18.0976 17.7069 17.7071C18.0974 17.3166 18.0974 16.6834 17.7069 16.2929L17.032 15.6181C19.8623 12.0844 19.6396 6.91177 16.3638 3.63603C15.9733 3.24551 15.3402 3.24551 14.9496 3.63603C14.5591 4.02656 14.5591 4.65972 14.9496 5.05025C17.4432 7.54386 17.6622 11.4508 15.6066 14.1926L14.1717 12.7578C15.458 10.8164 15.2459 8.17494 13.5354 6.46446C13.1449 6.07394 12.5117 6.07394 12.1212 6.46446C11.7307 6.85498 11.7307 7.48815 12.1212 7.87867C13.0449 8.80233 13.2403 10.1784 12.7076 11.2936L10.7162 9.30219C10.7101 9.29595 10.7039 9.28979 10.6977 9.2837L3.70686 2.29289Z"
      fill="currentColor"
    />
    <path
      d="M3.23741 8.1865C3.37988 7.65291 3.06281 7.10485 2.52921 6.96239C1.99562 6.81992 1.44757 7.137 1.3051 7.67059C0.504255 10.6701 1.27958 14.0074 3.6359 16.3637C4.02643 16.7542 4.65959 16.7542 5.05012 16.3637C5.44064 15.9732 5.44064 15.34 5.05012 14.9495C3.219 13.1184 2.61315 10.5246 3.23741 8.1865Z"
      fill="currentColor"
    />
    <path
      d="M7.40051 11.4995C7.1241 11.0214 6.51242 10.8578 6.03428 11.1343C5.55614 11.4107 5.39261 12.0223 5.66902 12.5005C5.88342 12.8714 6.14882 13.2198 6.46434 13.5353C6.85486 13.9258 7.48803 13.9258 7.87855 13.5353C8.26908 13.1448 8.26908 12.5116 7.87855 12.1211C7.68746 11.93 7.52841 11.7208 7.40051 11.4995Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpStatusOfflineIcon",
});
</script>
