/// <reference types="vite/types/importMeta" />
import Axios from "axios-observable";
import {
  ListDataConsentRequest,
  CreateDataConsentRequest,
  ListDataConsentTypeRequest,
  ListAllDataConsentRequest,
} from "../../requests/intranet/dataConsent.request";
import { apiPathFn } from "@ntprsrf-frontend/common/src/utils/api";
import { map } from "rxjs";
import {
  SuccessListResponse,
  SuccessPaginateResponse,
  SuccessSingleResponse,
} from "../../responses/base.response";
import {
  DataConsentModel,
  DataConsentTypeModel,
  TotalDataConsentModel,
} from "../../models/intranet/dataConsent.model";

const apiPath = apiPathFn(
  import.meta.env.VITE_INTRANET_FILE_SHARE_DATA_CONSENT_SERVICE
);

export function listDataConsentTypes(
  params: Partial<ListDataConsentTypeRequest> = {
    pageSize: 1000,
    pageNumber: 1,
  }
) {
  return Axios.get<SuccessPaginateResponse<DataConsentTypeModel>>(
    apiPath(["type"]),
    { params }
  ).pipe(
    map((response) => {
      if (response?.data?.data)
        return response.data.data;
      else throw new Error("Response Error");
    })
  );
}

export function listDataConsents(params: Partial<ListDataConsentRequest>) {
  return Axios.get<SuccessPaginateResponse<DataConsentModel>>(apiPath(), {
    params,
  }).pipe(map((response) => response.data));
}

export function listAllDataConsents(
  params: Partial<ListAllDataConsentRequest>
) {
  return Axios.get<SuccessListResponse<TotalDataConsentModel>>(
    apiPath(["list"]),
    {
      params,
    }
  ).pipe(
    map((response) => {
      if (response?.data?.data)
        return response.data.data;
      else throw new Error("Response Error");
    })
  );
}

export function createDataConsent(request: CreateDataConsentRequest) {
  return Axios.post<SuccessSingleResponse<DataConsentModel>>(
    apiPath(),
    request
  ).pipe(map((response) => response.data));
}

export function withdrawnDataConsent(dataConsentId: string) {
  return Axios.post<SuccessSingleResponse<DataConsentModel>>(
    apiPath(["withdrawn", dataConsentId])
  ).pipe(map((response) => response.data));
}

export function getDataConsentTypeBySlug(slug: string) {
  return Axios.get<SuccessSingleResponse<DataConsentTypeModel>>(
    apiPath(["type/by/slug", slug])
  ).pipe(map((response) => response.data));
}


export function getDataConsentFolder(
  params: Partial<{
    companyId: string;
    valueAddedServiceId: string;
  }>
) {
  return Axios.get<SuccessSingleResponse<TotalDataConsentModel>>(
    apiPath(["active", "consent"]),
    {
      params,
    }
  ).pipe(
    map((response) => {
      return response.data.data;
    })
  );
}