<template>
  <svg
    class="NtpIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.7068 10.293C14.8946 10.4805 15 10.7348 15 11C15 11.2652 14.8946 11.5195 14.7068 11.707L10.7018 15.707C10.5141 15.8945 10.2595 15.9998 9.99395 15.9998C9.72846 15.9998 9.47383 15.8945 9.28607 15.707L5.28106 11.707C5.09868 11.5184 4.99776 11.2658 5.00004 11.0036C5.00232 10.7414 5.10762 10.4906 5.29326 10.3052C5.4789 10.1198 5.73003 10.0146 5.99255 10.0123C6.25508 10.01 6.50799 10.1108 6.69683 10.293L8.9927 12.586V5C8.9927 4.73478 9.09819 4.48043 9.28596 4.29289C9.47373 4.10536 9.72841 4 9.99395 4C10.2595 4 10.5142 4.10536 10.7019 4.29289C10.8897 4.48043 10.9952 4.73478 10.9952 5V12.586L13.2911 10.293C13.4788 10.1055 13.7335 10.0002 13.999 10.0002C14.2645 10.0002 14.5191 10.1055 14.7068 10.293Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpArrowNarrowShortDownIcon",
});
</script>
