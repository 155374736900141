/// <reference types="vite/types/importMeta" />
import { apiPathFn } from "@ntprsrf-frontend/common/src/utils/api";
import { Axios } from "axios-observable";
import { map } from "rxjs";
import {
  ValueAddedServiceModel,
  ValueAddedServiceTypeModel,
} from "../../models/intranet/vas.models";
import { BaseListRequest } from "../../requests/base.request";
import {
  SuccessPaginateResponse,
  SuccessSingleResponse,
} from "../../responses/base.response";
import { ListVASRequest } from "../../requests/intranet/service.request";
import {
  ValueAddedServiceCreateRequestBody,
  ValueAddedServiceUpdateRequestBody,
} from "../../requests/intranet/vas.request";

const apiPath = apiPathFn(import.meta.env.VITE_INTRANET_COMPANY_SERVICE);
const apiPathFzFile = apiPathFn(import.meta.env.VITE_INTERNET_FZ_FILE_SERVICE);

export function getVASTypes(
  params: Partial<BaseListRequest> = {
    pageSize: 1000,
    pageNumber: 1,
  }
) {
  return Axios.get<SuccessPaginateResponse<ValueAddedServiceTypeModel>>(
    apiPath(["vas-services", "types"]),
    {
      params,
    }
  ).pipe(
    map((response) => {
      if (response?.data?.data)
        return response.data.data;
      else throw new Error("Response Error");
    })
  );
}

export function createVASTypes(request: Partial<ValueAddedServiceTypeModel>) {
  return Axios.post<SuccessSingleResponse<ValueAddedServiceTypeModel>>(
    apiPath(["vas-services", "types"]),
    request
  ).pipe(
    map((response) => {
      if (response?.data?.data)
        return response.data.data;
      else throw new Error("Response Error");
    })
  );
}

export function editVASTypes(
  request: Partial<ValueAddedServiceTypeModel>,
  vasTypeId: string
) {
  return Axios.put<SuccessSingleResponse<ValueAddedServiceTypeModel>>(
    apiPath(["vas-services", "types", vasTypeId]),
    request
  ).pipe(
    map((response) => {
      if (response?.data?.data)
        return response.data.data;
      else throw new Error("Response Error");
    })
  );
}

export function deleteVASTypes(vasTypeId: string) {
  return Axios.put<SuccessSingleResponse<string>>(
    apiPath(["vas-services", "types", "delete", vasTypeId])
  ).pipe(map((response) => response.data));
}

export function getVASList(params: Partial<ListVASRequest>) {
  return Axios.get<SuccessPaginateResponse<ValueAddedServiceModel>>(
    apiPath(["vas-services"]),
    {
      params: {
        ...params,
        valueAddedServiceTypeIds: params.valueAddedServiceTypeIds
          ? params.valueAddedServiceTypeIds.join(",")
          : undefined,
      },
    }
  ).pipe(map((response) => response.data));
}

export function getVASBySlug(slug: string) {
  return Axios.get<SuccessSingleResponse<ValueAddedServiceModel>>(
    apiPath(["vas-services", "slug", slug])
  ).pipe(
    map((response) => {
      if (response?.data?.data)
        return response.data.data;
      else throw new Error("Response Error");
    })
  );
}

export function getVASByCompanyUEN(
  companyUEN: string,
  params: BaseListRequest
) {
  return Axios.get<SuccessPaginateResponse<ValueAddedServiceModel>>(
    apiPath(["uen", companyUEN, "vas-services"]),
    {
      params,
    }
  ).pipe(map((response) => response.data));
}

export function getVASByType(
  valueAddedServiceTypeId: string,
  params: BaseListRequest
) {
  return Axios.get<SuccessPaginateResponse<ValueAddedServiceModel>>(
    apiPath(["type", valueAddedServiceTypeId, "vas-services"]),
    {
      params,
    }
  ).pipe(map((response) => response.data));
}

export function createVAS(vas: ValueAddedServiceCreateRequestBody) {
  return Axios.post<SuccessSingleResponse<ValueAddedServiceModel>>(
    apiPath(["vas-services", "save"]),
    vas
  ).pipe(
    map((response) => {
      if (response?.data?.data)
        return response.data.data;
      else throw new Error("Response Error");
    })
  );
}

export function updateVAS(
  vas: ValueAddedServiceUpdateRequestBody,
  vasId: string
) {
  return Axios.put<SuccessSingleResponse<ValueAddedServiceModel>>(
    apiPath(["vas-services", "update", vasId]),
    vas
  ).pipe(
    map((response) => {
      if (response?.data?.data)
        return response.data.data;
      else throw new Error("Response Error");
    })
  );
}

export function deleteVASById(id: string) {
  return Axios.delete<{
    name: string;
  }>(apiPath(["vas-services", "delete", id]));
}

export function delistVASById(id: string, reason = "None") {
  return Axios.post<{
    name: string;
  }>(apiPath(["vas-services", "delist", id]), {
    reason,
  });
}

export function getVASById(id: string) {
  return Axios.get<SuccessSingleResponse<ValueAddedServiceModel>>(
    apiPath(["vas-services", "id", id])
  ).pipe(map((res) => res.data.data));
}

export function uploadVASLogo(formData: FormData, comapanyId: string) {

  // Logo Upload without file scan
  // return Axios.post<
  //   SuccessSingleResponse<{
  //     logoPath: string;
  //   }>
  // >(apiPath(["vas-services", "upload", "logo", comapanyId]), formData).pipe(
  //   map((v) => v.data.data.logoPath)
  // );

  // Logo Upload with file scan
  return Axios.post<
    SuccessSingleResponse<{
      logoPath: string;
    }>
  >(apiPathFzFile(["upload", "vas-services", "upload", "logo", comapanyId]), formData).pipe(
    map((v) => v.data.data.logoPath)
  );
}
