import { RouteLocationRaw, RouteRecordRaw } from "vue-router";
import { S3CompanyModel } from "@ntprsrf-frontend/service/src/models/intranet/s3file.model";
import { ParamShareFileType } from "@ntprsrf-frontend/service/src/constants/share-files-type.constant";
import { eguaranteeTypes } from "@ntprsrf-frontend/internet/src/constants/APIEnums";

export function getCompanyFilesRoute(
  company: Partial<S3CompanyModel>,
  sharedPath: ParamShareFileType
): RouteLocationRaw {
  if (company.valueAddedService) {
    return {
      name: "VAS_COMPANY_FILES",
      params: {
        sharePath: sharedPath,
        otherCompanyUEN: company.companyUEN,
        vasSlug: company.valueAddedService?.slug,
        vasId: company.valueAddedService?.valueAddedServiceId,
      },
    };
  }
  return {
    name: "GENERAL_COMPANY_FILES",
    params: {
      sharePath: sharedPath,
      otherCompanyUEN: company.companyUEN,
    },
  };
}

const fileSharingRoute: RouteRecordRaw[] = [
  {
    path: "file-sharing",
    redirect: () => {
      return {
        name: "FILE_SHARING",
      };
    },
    children: [
      {
        meta: {
          label: "File Share",
          eguaranteeTypesCanAccess: [
            eguaranteeTypes.NORMAL.VALUE,
            eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
          ],
        },
        name: "FILE_SHARING",
        path: "",
        component: () => import("@/views/FileSharing/FileSharing.vue"),
      },
      {
        path: "services",
        component: () => import("@/views/ServiceSubscriptions/Services.vue"),
        name: "SERVICE",
        meta: {
          label: "Services",
          eguaranteeTypesCanAccess: [
            eguaranteeTypes.NORMAL.VALUE,
            eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
          ],
        },
        children: [
          {
            path: "service-subscriptions",
            name: "SERVICE_SUBSCRIPTIONS",
            component: () =>
              import("@/views/ServiceSubscriptions/MySubscriptions.vue"),
            meta: {
              label: "My Service Subscriptions",
              eguaranteeTypesCanAccess: [
                eguaranteeTypes.NORMAL.VALUE,
                eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
              ],
            },
          },
          {
            path: "service-subscribers",
            name: "SERVICE_SUBSCRIBERS",
            component: () =>
              import("@/views/ServiceSubscriptions/MySubscribers.vue"),
            meta: {
              label: "My Service Subscribers",
              eguaranteeTypesCanAccess: [
                eguaranteeTypes.NORMAL.VALUE,
                eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
              ],
            },
          },
        ],
      },
      {
        path: "company-list/:sharePath",
        meta: {
          breadcrumbParam: "sharePath",
        },
        children: [
          {
            path: "",
            name: "FILE_SHARING_COMPANY_LIST",
            component: () =>
              import("@/views/FileSharing/FileSharingCompanyList.vue"),
            meta: {
              eguaranteeTypesCanAccess: [
                eguaranteeTypes.NORMAL.VALUE,
                eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
              ],
            },
          },

          {
            path: ":otherCompanyUEN",
            component: () =>
              import("@/views/FileSharing/FileSharingCompanyFiles_Base.vue"),
            meta: {
              eguaranteeTypesCanAccess: [
                eguaranteeTypes.NORMAL.VALUE,
                eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
              ],
              isCompanyStore: true,
            },
            children: [
              {
                path: "general",
                meta: {
                  breadcrumbParam: "otherCompanyUEN",
                },
                children: [
                  {
                    path: "",
                    component: () =>
                      import("@/views/FileSharing/FileSharingCompanyFiles.vue"),
                    name: "GENERAL_COMPANY_FILES",
                  },
                  {
                    path: "add-files",
                    name: "GENERAL_COMPANY_ADD_FILES",
                    meta: {
                      label: "Add Files",
                    },
                    component: () => import("@/views/UploadFiles.vue"),
                  },
                ],
              },
              {
                path: "vas/:vasId/:vasSlug",
                meta: {
                  breadcrumbParam: "vasId",
                },
                children: [
                  {
                    path: "",
                    component: () =>
                      import("@/views/FileSharing/FileSharingCompanyFiles.vue"),
                    name: "VAS_COMPANY_FILES",
                  },
                  {
                    path: "add-files",
                    name: "VAS_COMPANY_ADD_FILES",
                    meta: {
                      label: "Add Files",
                    },
                    component: () => import("@/views/UploadFiles.vue"),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export default fileSharingRoute;
