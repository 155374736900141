<template>
  <svg
    class="NtpIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.40494 15.6968C7.01441 16.0873 7.01441 16.7204 7.40494 17.111C7.79546 17.5015 8.42863 17.5015 8.81915 17.111L7.40494 15.6968ZM15.1831 17.111C15.5736 17.5015 16.2068 17.5015 16.5973 17.111C16.9879 16.7204 16.9879 16.0873 16.5973 15.6968L15.1831 17.111ZM12.0011 19C11.4488 19 11.0011 19.4477 11.0011 20C11.0011 20.5523 11.4488 21 12.0011 21V19ZM12.0111 21C12.5634 21 13.0111 20.5523 13.0111 20C13.0111 19.4477 12.5634 19 12.0111 19V21ZM4.223 12.2218C3.83247 12.6124 3.83247 13.2455 4.223 13.6361C4.61352 14.0266 5.24669 14.0266 5.63721 13.6361L4.223 12.2218ZM18.3651 13.6361C18.7557 14.0266 19.3888 14.0266 19.7793 13.6361C20.1699 13.2455 20.1699 12.6124 19.7793 12.2218L18.3651 13.6361ZM0.687424 8.68629C0.2969 9.07681 0.2969 9.70998 0.687424 10.1005C1.07795 10.491 1.71111 10.491 2.10164 10.1005L0.687424 8.68629ZM21.9006 10.1005C22.2912 10.491 22.9243 10.491 23.3148 10.1005C23.7054 9.70998 23.7054 9.07682 23.3148 8.68629L21.9006 10.1005ZM8.81915 17.111C10.5765 15.3536 13.4258 15.3536 15.1831 17.111L16.5973 15.6968C14.0589 13.1584 9.94335 13.1584 7.40494 15.6968L8.81915 17.111ZM12.0011 21H12.0111V19H12.0011V21ZM5.63721 13.6361C9.15193 10.1213 14.8504 10.1213 18.3651 13.6361L19.7793 12.2218C15.4836 7.92607 8.51876 7.92607 4.223 12.2218L5.63721 13.6361ZM2.10164 10.1005C7.56898 4.63317 16.4333 4.63316 21.9006 10.1005L23.3148 8.68629C17.0665 2.4379 6.93581 2.4379 0.687424 8.68629L2.10164 10.1005Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpWifiIcon",
});
</script>
