<template>
  <svg
    class="NtpIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 10V11C21.5523 11 22 10.5523 22 10H21ZM21 14H22C22 13.4477 21.5523 13 21 13V14ZM3 14V13C2.44772 13 2 13.4477 2 14H3ZM3 10H2C2 10.5523 2.44772 11 3 11V10ZM5 4C3.34315 4 2 5.34315 2 7H4C4 6.44772 4.44772 6 5 6V4ZM19 4H5V6H19V4ZM22 7C22 5.34315 20.6569 4 19 4V6C19.5523 6 20 6.44772 20 7H22ZM22 10V7H20V10H22ZM20 12C20 11.4477 20.4477 11 21 11V9C19.3431 9 18 10.3431 18 12H20ZM21 13C20.4477 13 20 12.5523 20 12H18C18 13.6569 19.3431 15 21 15V13ZM22 17V14H20V17H22ZM19 20C20.6569 20 22 18.6569 22 17H20C20 17.5523 19.5523 18 19 18V20ZM5 20H19V18H5V20ZM2 17C2 18.6569 3.34315 20 5 20V18C4.44772 18 4 17.5523 4 17H2ZM2 14V17H4V14H2ZM4 12C4 12.5523 3.55228 13 3 13V15C4.65685 15 6 13.6569 6 12H4ZM3 11C3.55228 11 4 11.4477 4 12H6C6 10.3431 4.65685 9 3 9V11ZM2 7V10H4V7H2Z"
      fill="currentColor"
    />
    <path
      d="M15 5V7"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M15 11V13"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15 17V19"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpTicketIcon",
});
</script>
