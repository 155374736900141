<template>
  <svg
    class="NtpIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.6485 3.0839C5.15459 3.30502 5.3856 3.89454 5.16448 4.40062C4.41582 6.11413 4 8.00732 4 10.0003C4 11.9932 4.41582 13.8864 5.16448 15.5999C5.3856 16.106 5.15459 16.6955 4.6485 16.9166C4.14242 17.1377 3.5529 16.9067 3.33178 16.4006C2.47486 14.4394 2 12.2739 2 10.0003C2 7.72656 2.47486 5.56115 3.33178 3.59988C3.5529 3.09379 4.14242 2.86278 4.6485 3.0839Z"
      fill="currentColor"
    />
    <path
      d="M12.9613 7.00024C12.0499 7.00024 11.188 7.41451 10.6186 8.12616L10.2911 8.53553L10.1799 8.25746C9.87619 7.49815 9.14078 7.00024 8.32297 7.00024H8C7.44772 7.00024 7 7.44796 7 8.00024C7 8.55253 7.44772 9.00024 8 9.00024H8.32297L8.8551 10.3306L7.81962 11.6249C7.62985 11.8622 7.34253 12.0002 7.03875 12.0002H7C6.44772 12.0002 6 12.448 6 13.0002C6 13.5525 6.44772 14.0002 7 14.0002H7.03875C7.9501 14.0002 8.81204 13.586 9.38136 12.8743L9.70885 12.465L9.82008 12.743C10.1238 13.5023 10.8592 14.0002 11.677 14.0002H12C12.5523 14.0002 13 13.5525 13 13.0002C13 12.448 12.5523 12.0002 12 12.0002H11.677L11.1449 10.6699L12.1804 9.37555C12.3702 9.13833 12.6575 9.00024 12.9613 9.00024H13C13.5523 9.00024 14 8.55253 14 8.00024C14 7.44796 13.5523 7.00024 13 7.00024H12.9613Z"
      fill="currentColor"
    />
    <path
      d="M14.8355 4.40062C14.6144 3.89454 14.8454 3.30502 15.3515 3.0839C15.8576 2.86278 16.4471 3.09379 16.6682 3.59988C17.5251 5.56115 18 7.72656 18 10.0003C18 12.2739 17.5251 14.4394 16.6682 16.4006C16.4471 16.9067 15.8576 17.1377 15.3515 16.9166C14.8454 16.6955 14.6144 16.106 14.8355 15.5999C15.5842 13.8864 16 11.9932 16 10.0003C16 8.00732 15.5842 6.11413 14.8355 4.40062Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpVariableIcon",
});
</script>
