<template>
  <svg
    class="NtpIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 6C8.44772 6 8 6.44772 8 7C8 7.55228 8.44772 8 9 8V6ZM15 8C15.5523 8 16 7.55228 16 7C16 6.44772 15.5523 6 15 6V8ZM14 17C14 17.5523 14.4477 18 15 18C15.5523 18 16 17.5523 16 17H14ZM16 14C16 13.4477 15.5523 13 15 13C14.4477 13 14 13.4477 14 14H16ZM12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18V16ZM12.01 18C12.5623 18 13.01 17.5523 13.01 17C13.01 16.4477 12.5623 16 12.01 16V18ZM9 16C8.44772 16 8 16.4477 8 17C8 17.5523 8.44772 18 9 18V16ZM9.01 18C9.56229 18 10.01 17.5523 10.01 17C10.01 16.4477 9.56229 16 9.01 16V18ZM9 13C8.44772 13 8 13.4477 8 14C8 14.5523 8.44772 15 9 15V13ZM9.01 15C9.56229 15 10.01 14.5523 10.01 14C10.01 13.4477 9.56229 13 9.01 13V15ZM12 13C11.4477 13 11 13.4477 11 14C11 14.5523 11.4477 15 12 15V13ZM12.01 15C12.5623 15 13.01 14.5523 13.01 14C13.01 13.4477 12.5623 13 12.01 13V15ZM15 10C14.4477 10 14 10.4477 14 11C14 11.5523 14.4477 12 15 12V10ZM15.01 12C15.5623 12 16.01 11.5523 16.01 11C16.01 10.4477 15.5623 10 15.01 10V12ZM12 10C11.4477 10 11 10.4477 11 11C11 11.5523 11.4477 12 12 12V10ZM12.01 12C12.5623 12 13.01 11.5523 13.01 11C13.01 10.4477 12.5623 10 12.01 10V12ZM9 10C8.44772 10 8 10.4477 8 11C8 11.5523 8.44772 12 9 12V10ZM9.01 12C9.56229 12 10.01 11.5523 10.01 11C10.01 10.4477 9.56229 10 9.01 10V12ZM7 4H17V2H7V4ZM18 5V19H20V5H18ZM17 20H7V22H17V20ZM6 19V5H4V19H6ZM7 20C6.44772 20 6 19.5523 6 19H4C4 20.6569 5.34315 22 7 22V20ZM18 19C18 19.5523 17.5523 20 17 20V22C18.6569 22 20 20.6569 20 19H18ZM17 4C17.5523 4 18 4.44772 18 5H20C20 3.34315 18.6569 2 17 2V4ZM7 2C5.34315 2 4 3.34315 4 5H6C6 4.44772 6.44772 4 7 4V2ZM9 8H15V6H9V8ZM16 17V14H14V17H16ZM12 18H12.01V16H12V18ZM9 18H9.01V16H9V18ZM9 15H9.01V13H9V15ZM12 15H12.01V13H12V15ZM15 12H15.01V10H15V12ZM12 12H12.01V10H12V12ZM9 12H9.01V10H9V12Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpCalculatorIcon",
});
</script>
