<template>
  <svg
    class="NtpIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 5L9.70711 4.29289L9.41421 4H9V5ZM11 7L10.2929 7.70711L10.5858 8H11V7ZM16 10C16 10.5523 16.4477 11 17 11C17.5523 11 18 10.5523 18 10H16ZM4 17V7H2V17H4ZM5 6H9V4H5V6ZM8.29289 5.70711L10.2929 7.70711L11.7071 6.29289L9.70711 4.29289L8.29289 5.70711ZM11 8H15V6H11V8ZM15 8C15.5523 8 16 8.44772 16 9H18C18 7.34315 16.6569 6 15 6V8ZM4 7C4 6.44772 4.44772 6 5 6V4C3.34315 4 2 5.34315 2 7H4ZM2 17C2 18.6569 3.34315 20 5 20V18C4.44772 18 4 17.5523 4 17H2ZM16 9V10H18V9H16ZM8 12C8 11.4477 8.44772 11 9 11V9C7.34315 9 6 10.3431 6 12H8ZM9 11H19V9H9V11ZM19 11C19.5523 11 20 11.4477 20 12H22C22 10.3431 20.6569 9 19 9V11ZM20 12V17H22V12H20ZM20 17C20 17.5523 19.5523 18 19 18V20C20.6569 20 22 18.6569 22 17H20ZM19 18H5V20H19V18ZM5 20C6.65685 20 8 18.6569 8 17H6C6 17.5523 5.55228 18 5 18V20ZM8 17V12H6V17H8Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpFolderOpenIcon",
});
</script>
