import { defineStore } from "pinia";
import { firstValueFrom, Observable } from "rxjs";
import {
  logout,
  verifyToken,
} from "@ntprsrf-frontend/service/src/services/intranet/auth.service";
import { PortalLoginResponse } from "@ntprsrf-frontend/service/src/responses/intranet/auth.response";
import {
  eguaranteeTypes,
  CompanyTypes,
} from "@ntprsrf-frontend/internet/src/constants/APIEnums";
import type { Router } from "vue-router";
import { INTERNET_LOGIN_URL } from "@ntprsrf-frontend/common/src/constants/login.constants";
import { redirectProcess } from "@ntprsrf-frontend/common/src/utils/corppass";

declare module "pinia" {
  export interface PiniaCustomProperties {
    $router: Router;
  }
}
interface AuthState {
  userId: string;
  username: string;
  companyId: string;
  companyName: string;
  companyUEN: string;
  companyType: string;
  eguaranteeType: string;
  roleId: string;
  roleType: string;
  isFirstLogin: boolean;
  hasAgreedToTNC?: boolean;
  loginKey: string;
}

export default defineStore("auth", {
  persist: true,
  state: (): AuthState => ({
    userId: "",
    username: "",
    companyId: "",
    companyName: "",
    companyUEN: "",
    companyType: CompanyTypes.DEFAULT.VALUE,
    eguaranteeType: "",
    roleId: "",
    roleType: "",
    isFirstLogin: false,
    hasAgreedToTNC: false,
    loginKey: ""
  }),
  getters: {
    isLogin: (state) => !!state.userId,
    isVASProvider: (state) =>
      state.companyType === CompanyTypes.VAS_PROVIDER.VALUE,
    isNormalEGuaranteeType(state) {
      return state.eguaranteeType == eguaranteeTypes.NORMAL.VALUE;
    },
    isGovernmentAgency(state) {
      return state.eguaranteeType == eguaranteeTypes.GOVERNMENT_AGENCY.VALUE;
    },
    isFinancialInstitution(state) {
      return (
        state.eguaranteeType == eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE
      );
    },
    getUserEguaranteeType(state) {
      return state.eguaranteeType;
    },
  },
  actions: {
    setUserInfo(loginResponse: PortalLoginResponse) {
      this.userId = loginResponse.data.user.userId;
      this.username = loginResponse.data.user.username;
      this.companyId = loginResponse.data.company.companyId;
      this.companyName = loginResponse.data.company.companyName;
      this.companyUEN = loginResponse.data.company.companyUEN;
      this.companyType = loginResponse.data.company.companyType ?? CompanyTypes.DEFAULT.VALUE;
      this.eguaranteeType = loginResponse.data.company.eguaranteeType;
      this.roleId = loginResponse.data.user.role.roleId;
      this.roleType = loginResponse.data.user.role.roleType;
      this.isFirstLogin = loginResponse.data.isFirstLogin;
      this.loginKey = String(Math.ceil(Math.random() * 1000));
    },
    setTNCAgreement(hasAgreed: boolean) {
      this.hasAgreedToTNC = hasAgreed;
    },
    async login(obs: Observable<PortalLoginResponse>) {
      const response = await firstValueFrom(obs);
      this.setUserInfo(response);
      if (this.isFirstLogin) {
        await this.$router.push({
          name: "TERMS_AND_CONDITIONS"
        });
        return;
      }
      redirectProcess(this.$router);
    },
    async signOut(gotoTimeout: boolean = false) {
      try {
        await firstValueFrom(logout());
      } finally {
        if (gotoTimeout) {
          await this.resetStoreThenGoToTimeoutPage();
        } else {
          await this.resetStoreThenGoToHome();
        }
        localStorage.removeItem('serviceDetailId');
        localStorage.removeItem("redirect");
      }
    },
    async resetStoreThenGoToTimeoutPage() {
      this.$reset();
      await this.$router.replace({
        name: "TIMEOUT",
      });
    },
    async resetStoreThenGoToHome() {
      this.$reset();
      if (INTERNET_LOGIN_URL) {
        window.location.href = INTERNET_LOGIN_URL;
        return;
      }
      await this.$router.push({
        name: "HOME PAGE",
      });
    },
    async callVerifyToken() {
      const response = await firstValueFrom(verifyToken());
      if (
        this.userId !== response.data.user.userId ||
        this.companyId !== response.data.company.companyId
      ) {
        this.userId = response.data.user.userId;
        this.username = response.data.user.username;
        this.companyId = response.data.company.companyId;
        this.companyName = response.data.company.companyName;
        this.companyUEN = response.data.company.companyUEN;
        this.companyType = response.data.company.companyType ?? CompanyTypes.DEFAULT.VALUE;
        this.eguaranteeType = response.data.company.eguaranteeType;
        this.isFirstLogin = response.data.isFirstLogin;
      }
    },
  },
});
