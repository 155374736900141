<template>
  <svg
    class="NtpIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 19.0006V10.071C3 9.40226 3.3342 8.77779 3.8906 8.40686L10.8906 3.7402C11.5624 3.29233 12.4376 3.29233 13.1094 3.7402L20.1094 8.40686C20.6658 8.77779 21 9.40226 21 10.071V19.0006M3 19.0006C3 20.1052 3.89543 21.0006 5 21.0006H19C20.1046 21.0006 21 20.1052 21 19.0006M3 19.0006L9.75 14.5006M21 19.0006L14.25 14.5006M3 10.0006L9.75 14.5006M21 10.0006L14.25 14.5006M14.25 14.5006L13.1094 15.261C12.4376 15.7089 11.5624 15.7089 10.8906 15.261L9.75 14.5006"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpMailOpenIcon",
});
</script>
