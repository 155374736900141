<template>
  <svg
    class="NtpIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.3006 1.04633C11.7169 1.17755 12 1.5636 12 2.00007V7.00007L16 7.00007C16.3729 7.00007 16.7148 7.20753 16.887 7.53826C17.0592 7.86899 17.0331 8.26806 16.8192 8.57353L9.81924 18.5735C9.56894 18.9311 9.11564 19.085 8.69936 18.9538C8.28309 18.8226 8 18.4365 8 18.0001L8 13.0001H4C3.62713 13.0001 3.28522 12.7926 3.11302 12.4619C2.94083 12.1312 2.96694 11.7321 3.18077 11.4266L10.1808 1.42661C10.4311 1.06904 10.8844 0.915114 11.3006 1.04633Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpLightningBoltIcon",
});
</script>
