import { h } from "vue";
import {
	NtpCheckCircleIcon,
	NtpExclamationCircleIcon,
	NtpXCircleIcon,
	NtpInformationCircleIcon,
} from './icons/outline';
import { NtpXIcon } from './icons/solid';
import { useTippy } from "vue-tippy";
import ColorType from "../utils/colorType";

export type NotificationType = ColorType;

export const useToast = (props: { vnode: any }) => {
	const tippy = useTippy(document.body, {
		placement: "right-start",
		hideOnClick: false,
		trigger: "manual",
		interactive: true,
		theme: 'toast',
		arrow: false,
		offset: [0, 0],
		moveTransition: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2",
		getReferenceClientRect: () => ({
			width: 0,
			height: 0,
			top: 0,
			right: 0,
		}),
	});
	const tippyContent = h(
		"div",
		{
			class: "flex w-full justify-end",
		},
		[
			h(
				"div",
				{
					class:
						"flex mr-3 mt-3 p-4 bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden",
				},
				[
					props.vnode,
					h(
						"div",
						{
							class: "ml-4 flex-shrink-0 flex items-start",
						},
						[
							h(
								"button",
								{
									class:
										"bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500",
									type: "button",
									onClick: () => tippy.hide(),
								},
								[
									h("span", {
										class: "sr-only",
										innerHTML: "Close",
									}),
									h(NtpXIcon, {
										class: "h-5 w-5",
										"aria-hidden": "true",
									}),
								],
							),
						],
					),
				],
			),
		],
	);
	tippy.setContent(tippyContent);

	return tippy;
};

const notificationIconMap = {
	success: NtpCheckCircleIcon,
	info: NtpInformationCircleIcon,
	danger: NtpExclamationCircleIcon,
	warning: NtpXCircleIcon,
	secondary: NtpInformationCircleIcon,
};

export const useCustomNotification = (
	notiType: NotificationType = "info",
	timeOut = 6000,
) => {
	return (
		title = [h("div")],
		subtitle = [h("div")],
		_notiType = notiType,
		_timeOut = timeOut,
	) => {
		const vnode = h(
			"div",
			{
				class: "flex w-[300px]",
				role: "alertdialog"
			},
			[
				h(
					"div",
					{
						class: "flex-shrink-0",
					},
					[
						h(
							"div",
							{
								class: "NTP_Text w-6 h-6",
								color: _notiType,
							},
							[h(
								notificationIconMap[_notiType],
								{
									color: _notiType
								}
							)],
						),
					],
				),
				h(
					"div",
					{
						class: "ml-3 w-0 flex-1 pt-0.5 flex flex-col",
					},
					[
						h(
							"div",
							{
								class: "text-sm font-medium text-gray-900",
							},
							title,
						),
						h(
							"div",
							{
								class: "mt-1 text-sm text-gray-500",
							},
							[subtitle],
						),
					],
				),
			],
		);
		const tippy = useToast({
			vnode,
		});
		tippy.show();
		if (_timeOut > 0) {
			setTimeout(() => {
				tippy.destroy();
			}, timeOut);
		}
	};
};

const useNotification = (
	notiType: NotificationType = "info",
	timeOut = 6000,
) => {
	const notification = useCustomNotification(notiType, timeOut);

	return (
		title: string,
		subtitle: string,
		_notiType = notiType,
		_timeOut = timeOut,
	) => {
		notification(
			[
				h("div", {
					innerHTML: title,
					color: notiType,
					class: 'NtpNotification'
				}),
			],
			[
				h("div", {
					innerHTML: subtitle,
				}),
			],
			_notiType,
			_timeOut,
		);
	};
};

export default useNotification;