import get from "lodash.get";
import { RouteLocationNormalized } from "vue-router";
import useAuthStore from "../../stores/auth.store";

const FULL_PATH_LIST = ["/", "/login", "/login-test"];

export function canUserAccess(to: RouteLocationNormalized) {
  const store = useAuthStore();
  const eguaranteeTypesCanAccess: string[] = get(
    to,
    "meta.eguaranteeTypesCanAccess",
    []
  ) as string[];
  if (FULL_PATH_LIST.includes(to.fullPath)) {
    return true;
  } else if (eguaranteeTypesCanAccess.length > 0) {
    return eguaranteeTypesCanAccess.includes(store.getUserEguaranteeType);
  } else return undefined;
}

export function isUserFirstLogin(to: RouteLocationNormalized) {
  const store = useAuthStore();

  if ([...FULL_PATH_LIST.slice(1), "/update-profile", "/terms-and-conditions"].includes(to.path)) {
    return false;
  }

  return store.isFirstLogin;
}
