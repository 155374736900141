import { defineStore } from "pinia";

interface TimerState {
  from?: Date | string;
}

export default defineStore("timer", {
  persist: true,
  state: (): TimerState => ({
    from: new Date(),
  }),
  getters: {
    duration(state) {
      const padTo2Digits = (num: number) => {
        return num.toString().padStart(2, '0');
      }

      const milliseconds = Math.abs(new Date() - state.from);

      let seconds = Math.floor(milliseconds / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
    
      seconds = seconds % 60;
      minutes = minutes % 60;


      return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
        seconds,
      )}`;
    },
    durationInMillisecs(state) {
      const milliseconds = Math.abs(new Date() - state.from);

      return milliseconds;
    },
  },
});
