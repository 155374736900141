<template>
  <svg
    class="NtpIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58173 2 2 5.58172 2 10C2 14.4183 5.58173 18 10 18ZM11 7C11 6.44772 10.5523 6 10 6C9.44773 6 9.00002 6.44772 9.00002 7L9.00002 10.5858L7.70712 9.29289C7.31659 8.90237 6.68343 8.90237 6.2929 9.29289C5.90238 9.68342 5.90238 10.3166 6.2929 10.7071L9.29291 13.7071C9.68343 14.0976 10.3166 14.0976 10.7071 13.7071L13.7071 10.7071C14.0977 10.3166 14.0977 9.68342 13.7071 9.29289C13.3166 8.90237 12.6834 8.90237 12.2929 9.29289L11 10.5858V7Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpArrowCircleDownIcon",
});
</script>
