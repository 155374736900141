import { defineStore } from "pinia";
import { firstValueFrom, map, Observable } from "rxjs";
import { RouteLocationNormalizedLoaded } from "vue-router";
import useCompanyStore from "@ntprsrf-frontend/internet/src/stores/company.store";


export type NavInfo = {
    title: string;
    subtitle: string;
    navTitle: string;
};


export const strNavInfo = (obs: Observable<string>) => obs.pipe(
    map(res => <NavInfo>({
      title: res,
      subtitle: '',
      navTitle: res
    }))
  );
  

export const useRouteStoreFn = <T>(params: {
    storeName: string
    getNavInfo: (res: T) => NavInfo,
    getInfoFromRoute: (cRoute: RouteLocationNormalizedLoaded) => Observable<T> 
}) => defineStore(params.storeName, {
    state() {
        return {
            detail: <T>{}
        }
    },
    getters: {
        getNavInfo: (st) => params.getNavInfo(st.detail)
    },
    actions: {
        setRouteInfo: async function(route: RouteLocationNormalizedLoaded) {
            this.detail = await firstValueFrom(params.getInfoFromRoute(route));
        },
        setDetails: function(data: T) {
            this.detail = data;
        }
    }
});

export type RouteStoreType = ReturnType<ReturnType<typeof useRouteStoreFn>>
