<template>
  <svg
    class="NtpIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 3C3.89543 3 3 3.89543 3 5V15C3 16.1046 3.89543 17 5 17H15C16.1046 17 17 16.1046 17 15V5C17 3.89543 16.1046 3 15 3H14C13.4477 3 13 3.44772 13 4C13 4.55228 13.4477 5 14 5H15V12H13L12 14H8L7 12H5V5L6 5C6.55228 5 7 4.55228 7 4C7 3.44772 6.55228 3 6 3H5Z"
      fill="currentColor"
    />
    <path
      d="M7.2929 7.29289C7.68342 6.90237 8.31659 6.90237 8.70711 7.29289L9 7.58579L9 3C9 2.44772 9.44772 2 10 2C10.5523 2 11 2.44771 11 3V7.58579L11.2929 7.29289C11.6834 6.90237 12.3166 6.90237 12.7071 7.29289C13.0976 7.68342 13.0976 8.31658 12.7071 8.70711L10.7071 10.7071C10.5196 10.8946 10.2652 11 10 11C9.73479 11 9.48043 10.8946 9.2929 10.7071L7.2929 8.70711C6.90237 8.31658 6.90237 7.68342 7.2929 7.29289Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpInboxInIcon",
});
</script>
