<template>
  <svg
    class="NtpIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.70712 3.29289C8.09765 3.68342 8.09765 4.31658 7.70712 4.70711L5.41422 7H11C14.866 7 18 10.134 18 14V16C18 16.5523 17.5523 17 17 17C16.4478 17 16 16.5523 16 16V14C16 11.2386 13.7615 9 11 9H5.41422L7.70712 11.2929C8.09765 11.6834 8.09765 12.3166 7.70712 12.7071C7.3166 13.0976 6.68343 13.0976 6.29291 12.7071L2.29289 8.70711C1.90237 8.31658 1.90237 7.68342 2.29289 7.29289L6.29291 3.29289C6.68343 2.90237 7.3166 2.90237 7.70712 3.29289Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpReplyIcon",
});
</script>
