<template>
  <svg
    class="NtpIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0005 9.5C18.0005 10.3284 17.329 11 16.5005 11C15.6721 11 15.0005 10.3284 15.0005 9.5V3.5C15.0005 2.67157 15.6721 2 16.5005 2C17.329 2 18.0005 2.67157 18.0005 3.5V9.5Z"
      fill="currentColor"
    />
    <path
      d="M14.0005 9.66667V4.23607C14.0005 3.47852 13.5725 2.786 12.895 2.44721L12.8451 2.42229C12.2897 2.14458 11.6772 2 11.0563 2L5.64013 2C4.68677 2 3.86594 2.67292 3.67897 3.60777L2.47897 9.60777C2.23145 10.8453 3.17804 12 4.44013 12H8.00052V16C8.00052 17.1046 8.89595 18 10.0005 18C10.5528 18 11.0005 17.5523 11.0005 17V16.3333C11.0005 15.4679 11.2812 14.6257 11.8005 13.9333L13.2005 12.0667C13.7198 11.3743 14.0005 10.5321 14.0005 9.66667Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpThumbDownIcon",
});
</script>
