<template>
  <svg
    class="NtpIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8434 9.65538C14.2053 10.0725 14.8369 10.1173 15.254 9.7553C15.6712 9.39335 15.7159 8.76176 15.354 8.34462L13.8434 9.65538ZM10.1567 14.3446C9.79471 13.9275 9.16313 13.8827 8.74599 14.2447C8.32885 14.6067 8.28411 15.2382 8.64607 15.6554L10.1567 14.3446ZM13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7H13ZM11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17L11 17ZM20 12C20 16.4183 16.4183 20 12 20V22C17.5228 22 22 17.5228 22 12H20ZM12 20C7.58172 20 4 16.4183 4 12H2C2 17.5228 6.47715 22 12 22V20ZM4 12C4 7.58172 7.58172 4 12 4V2C6.47715 2 2 6.47715 2 12H4ZM12 4C16.4183 4 20 7.58172 20 12H22C22 6.47715 17.5228 2 12 2V4ZM12 11C11.3415 11 10.7905 10.8202 10.4334 10.5822C10.0693 10.3394 10 10.1139 10 10H8C8 10.9907 8.6023 11.7651 9.32398 12.2463C10.0526 12.732 11.0017 13 12 13V11ZM10 10C10 9.8861 10.0693 9.66058 10.4334 9.41784C10.7905 9.17976 11.3415 9 12 9V7C11.0017 7 10.0526 7.26796 9.32398 7.75374C8.6023 8.23485 8 9.00933 8 10H10ZM12 9C12.9038 9 13.563 9.33231 13.8434 9.65538L15.354 8.34462C14.5969 7.47209 13.3171 7 12 7V9ZM12 13C12.6585 13 13.2095 13.1798 13.5666 13.4178C13.9308 13.6606 14 13.8861 14 14H16C16 13.0093 15.3977 12.2348 14.676 11.7537C13.9474 11.268 12.9983 11 12 11V13ZM11 7V8H13V7H11ZM11 16L11 17L13 17L13 16L11 16ZM12 15C11.0962 15 10.437 14.6677 10.1567 14.3446L8.64607 15.6554C9.40317 16.5279 10.683 17 12 17L12 15ZM14 14C14 14.1139 13.9308 14.3394 13.5666 14.5822C13.2095 14.8202 12.6586 15 12 15V17C12.9983 17 13.9474 16.732 14.676 16.2463C15.3977 15.7651 16 14.9907 16 14H14ZM11 8L11 16L13 16L13 8L11 8Z"
      fill="currentColor"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NtpCurrencyDollarIcon",
});
</script>
