export const CompanyTypes = {
  DEFAULT: {
    NAME: "Normal",
    VALUE: "NORMAL",
  },
  VAS_PROVIDER: {
    NAME: "Service Provider",
    VALUE: "SERVICE_PROVIDER",
  },
};


export type EguaranteeKey = 'NORMAL' | 'GOVERNMENT_AGENCY' | 'FINANCIAL_INSTITUTION';

export const eguaranteeTypes: Record<EguaranteeKey, {
  NAME: string;
  VALUE: EguaranteeKey
}> = {
  NORMAL: {
    NAME: "Normal",
    VALUE: "NORMAL",
  },
  GOVERNMENT_AGENCY: {
    NAME: "Government Agency",
    VALUE: "GOVERNMENT_AGENCY",
  },
  FINANCIAL_INSTITUTION: {
    NAME: "Financial Institution",
    VALUE: "FINANCIAL_INSTITUTION",
  },
};


export const FsrStatusEnum = {
  REQUESTED: {
    NAME: "Pending",
    VALUE: "REQUESTED",
  },
  APPROVED: {
    NAME: "Accepted",
    VALUE: "APPROVED",
  },
  REJECTED: {
    NAME: "Rejected",
    VALUE: "REJECTED",
  },
  TERMINATED: {
    NAME: "Terminated",
    VALUE: "TERMINATED",
  },
  UNSUBSCRIBED: {
    NAME: "Unsubscribed",
    VALUE: "UNSUBSCRIBED",
  },
  INPROGRESS: {
    NAME: "Processing",
    VALUE: "INPROGRESS",
  },
};

export const GenerateReportEnum = {
  FAILED: {
    NAME: "Failed",
    VALUE: "FAILED",
  },
  DOWNLOADED: {
    NAME: "Downloaded",
    VALUE: "DOWNLOADED",
  },
  GENERATED: {
    NAME: "Generated",
    VALUE: "GENERATED",
  },
  PENDING: {
    NAME: "Pending",
    VALUE: "PENDING",
  }
}
